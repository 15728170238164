import React, { Component } from 'react';
import { connect } from 'react-redux';
import SortableTree from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import 'react-sortable-tree/style.css';
import * as treeBuilderActions from '../actions/index';
import Search from '../components/searchComponent';
import ContextMenuComponent from '../components/contextMenuComponent';
import TreeOptions from './treeOptionsContainer';
import * as treeViewUtility from '../../utility/treeViewUtility';
import Loader from '../../hoc/loader';
import { TreeContext } from '../provider/treeProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import ModalWindow from '../../hoc/modelWindow';
import { FormattedMessage } from 'react-intl';

class Tree extends Component {
  static contextType = TreeContext;
  state = {
    searchString: "",
    searchFocusIndex: 0,
    searchFoundCount: 0,
    tree: [],
    clickedNode: null,
    contextClass: "",
    openModal: false,
    action: "",
    loading: false,
    contextPos: "",
    contextPosX: "",
    selectedNodeClassName:"",
    isDisplayExpandIcon: false,
    revertChangesModal: false,
  };

  inpRef = React.createRef();

  componentDidMount() {
    this.setState({
      tree: this.props.tree
    })
  }

  componentWillMount() {
    document.addEventListener('mousedown', (evt) => {
      if (evt.target.outerHTML.indexOf("<li>") !== 0) {
        this.setState({
          contextClass: ""
        })
      }
    });
  }

  componentWillUnmount() {
    //    document.removeEventListener('mousedown');
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.tree !== nextProps.tree) {
      this.setState({
        tree: nextProps.tree,
        loading: false
      })
    }
  }

  setStateselectPrevMatch = () => {
    this.setState({
      searchFocusIndex: this.state.searchFocusIndex !== null ? (this.state.searchFoundCount + this.state.searchFocusIndex - 1) % this.state.searchFoundCount : this.state.searchFoundCount - 1,
    });
  }

  selectNextMatch = () => {
    this.setState({
      searchFocusIndex: this.state.searchFocusIndex !== null ? (this.state.searchFocusIndex + 1) % this.state.searchFoundCount : 0,
    });
  }

  disableOrEnableDrag = (data) => {
    if(data.node.type === 'SITE' || data.node.type === 'FEEDER'| data.node.type === 'LATERAL' || data.node.type === 'LATERAL_SITE'){
      return true
    }
  }

  disableOrEnableDrop=(data)=>{
    if(data && data.prevParent && data.nextParent && data.prevParent.name === data.nextParent.name) {
      return true
    }
  }

  onMoveNodeData = (data) => {
    if (JSON.stringify(data.path) !== JSON.stringify(data.prevPath)) {
      this.context.updateDragStateChange(data)
    }
  }

  openContextMenu = (event, clickedNode) => {
    this.setState({
      clickedNode: clickedNode,
      contextClass: "context" + clickedNode.node.type + clickedNode.node.name,
      contextPos: event.pageY,
      contextPosX: event.target.children.length > 0 ? event.target.innerText.length + 100 : event.pageX
    })
  }

  callRespectiveAction = (event, action) => {
    event.stopPropagation();
    this.setState({
      action: action,
      openModal: true,
      contextClass: ""
    })
  }

  closeModal = () => {
    this.setState({ openModal: false })
  }

  componentDidUpdate() {
    if (this.context.state.selectedItem !== this.state.selectedNodeClassName) {
      this.setState({ selectedNodeClassName: this.context.state.selectedItem });
    }
  }

   showOrHideIconOnMouseHvr =()=> this.setState({ isDisplayExpandIcon : true })

   showOrHideIconOnMouseOut=()=> this.setState({ isDisplayExpandIcon : !this.props.hideAndShowTreeView ? false : true })

  updateExpCollIcon=(e)=>{
    this.props.updateExpandCollapse(true)
  }

  updateDragDropPosition=(data)=>{
    if (data === 'reset'){
      this.setState({
        revertChangesModal: true
      })
    } else {
      this.context.closeDragState(true);
      this.context.updateNodePositions();
    }
  }

  closeRevertChangesModal= () => {
    this.setState({ revertChangesModal: false })
  }
  onOkClick = () => {
    this.context.closeDragState(false);
    this.closeRevertChangesModal();
    this.context.urlUpdate();
  }
  onClickHandler = (btnName) => {
    return btnName === "ok" ? this.onOkClick() : this.closeRevertChangesModal();
}

  render() {
    let modalTitle = <div className="node-confirmation-title">
        <FormattedMessage id={"tree.confirmation"} /> 
      </div>;
    let modalContent = <div className="node-confirmation-content">
        <FormattedMessage id={"tree.revertChanges"} /> 
      </div>;
    let modalFooter = [{ className: "modalCancelBtn", name: <FormattedMessage id={`dm.column.cancel`} />, value: "cancel" }, { className: "modalSaveBtn", name: <FormattedMessage id={`common.button.ok`} />, value: "ok" }];
    return (
          <React.Fragment>
            
            {this.context.state.loading ? <Loader elementClass="tree-loader p-0 m-0" /> : null}
            <div className={"left-nav content-height"}>
             {!["/events/register","/insights/impendingOutages","/events/details","/mapView"].includes(this.props.currentRoute) && <span className={this.context.state.breadCrumb ? "breadCrumb-style mb-2" : "d-none"}>{this.context.state.breadCrumb}</span>}
              <Search
                // searchFoundCount={this.state.searchFoundCount}
                // selectPrevMatch={this.selectPrevMatch}
                // selectNextMatch={this.selectNextMatch}
                // searchFocusIndex={this.state.searchFocusIndex}
                // inputChangedHandler={evt => this.setState({ searchString: evt.target.value })}
                searchResults = {this.context.state.searchResult}
                showDropdown = {this.context.state.showDropdown}
                searchedString = {this.context.state.searchedString}
                inputChangedHandler={evt => this.context.inputChangedHandler(evt)}
                selectSearchedNode={(node) => this.context.selectSearchedNode(node)}
                onBlur = {this.context.onBlur}
              />
              <div className="tree" onMouseEnter={this.showOrHideIconOnMouseHvr} onMouseLeave={this.showOrHideIconOnMouseOut}>
                { this.state.isDisplayExpandIcon ? 
                    <span className="onhvr-expnd-clps" onClick={ (e)=> this.updateExpCollIcon(e) }> <FontAwesomeIcon icon={faChevronLeft} size="lg" /> </span>
                  : "" }
                {
                  (this.context.state.tree && this.context.state.tree.length === 0) ? <Loader elementClass="tree-ajax-loader" /> :
                    <div className='treeHeight'>

                      { this.context.state.dragState && <div className='dragIcons'>
                        <FontAwesomeIcon icon={faTimesCircle} size='lg' onClick={()=>this.updateDragDropPosition('reset')}/>
                        <FontAwesomeIcon icon={faCheckCircle} size='lg' onClick={()=>this.updateDragDropPosition('update')}/>
                      </div>}

                    <SortableTree
                      treeData={this.context.state.tree}
                      onChange={treeData => { this.props.onDropTree(treeData) }}
                      theme={FileExplorerTheme}
                      searchQuery={this.context.searchQuery}
                      searchFocusOffset={this.state.searchFocusIndex}
                      canDrag={(clickedNode) => this.disableOrEnableDrag(clickedNode)}
                      canDrop={(dragedNode) => this.disableOrEnableDrop(dragedNode)}
                      onMoveNode={(moveNode)=> this.onMoveNodeData(moveNode)}

                      searchFinishCallback={matches => this.setState({
                        searchFoundCount: matches.length,
                        searchFocusIndex: matches.length > 0 ? this.state.searchFocusIndex % matches.length : 0
                      })}
                      generateNodeProps={clickedNode => {
                        let nodeProps={
                        onContextMenu: (event) => {
                          event.preventDefault();
                          if (location.href.includes("manage/details"))
                            this.openContextMenu(event, clickedNode)
                        },
                        buttons: [
                          <div className={(["insights","events"].some(s => this.props.currentRoute.includes(s)) && (clickedNode.node.type === 'SITE' || clickedNode.node.type === "LATERAL" || clickedNode.node.type === "LATERAL_SITE")) ? 'disabled' : ''}><span onClick={() => this.context.loadNextLevel(clickedNode)} className={clickedNode.node.type !== 'SITE' && clickedNode.node.type !== "LATERAL_SITE" ? clickedNode.node.arrow === 'right' ? `fas fa-chevron-right ${clickedNode.node.id + "_" + clickedNode.node.name.replace(/ /g, '')}` : (clickedNode.node.arrow === 'down' ? `fas fa-chevron-down ${clickedNode.node.id + "_" + clickedNode.node.name.replace(/ /g, '')}` : `${clickedNode.node.id + "_" + clickedNode.node.name.replace(/ /g, '')}`) : `${clickedNode.node.id + "_" + clickedNode.node.name.replace(/ /g, '')}`}>{treeViewUtility.getIcon(clickedNode)}</span></div>,
                              <div className={(["insights","events"].some(s => this.props.currentRoute.includes(s)) && (clickedNode.node.type === 'SITE' || clickedNode.node.type === "LATERAL" || clickedNode.node.type === "LATERAL_SITE")) ? 'disabled' : ''}><div className="rstcustom__rowLabel" onClick={(e) => {
                             this.context.loadNextLevel(clickedNode, null, null, clickedNode.node.name)
                          }}>
                            <span>{clickedNode.node.name}</span></div></div>,
                          <div style={{ display: this.state.contextClass === "context" + clickedNode.node.type + clickedNode.node.name ? 'block' : 'none' }}>
                            <ContextMenuComponent clicked={(event, action) => this.callRespectiveAction(event, action)} contextPos={this.state.contextPos} contextPosX={this.state.contextPosX} selectedNode={clickedNode.node} roleAndPermission={this.props.roleAndPermission} />
                          </div>
                        ],
                        ref: (inp) => {
                          this[clickedNode.node.id + "_" + clickedNode.node.name.replace(/ /g, '')] = inp;
                          this.context.setTreeRef(this[clickedNode.node.id + "_" + clickedNode.node.name.replace(/ /g, '')], clickedNode.node.id + "_" + clickedNode.node.name.replace(/ /g, ''))
                        }
                      }
                      if (this.state.selectedNodeClassName === `shadow${treeViewUtility.getClassName(clickedNode)}`) {
                        nodeProps.className = (["insights","events"].some(s => this.props.currentRoute.includes(s)) && (clickedNode.node.type === 'SITE' || clickedNode.node.type === "LATERAL" || clickedNode.node.type === "LATERAL_SITE")) ? '' : 'selected-feature-node';
                    }
                    if(["insights","events"].some(s => this.props.currentRoute.includes(s)) && clickedNode.node.type === 'FEEDER' && (this.state.selectedNodeClassName.split('-').slice(0,4).join('-') === `shadow${treeViewUtility.getClassName(clickedNode)}`) ){
                      nodeProps.className= 'selected-feature-node' 
                    }
                    return nodeProps;
                      }
                    }
                    />
                    </div>
                }
              </div>
            </div>
        {
          this.state.openModal ?
            <TreeOptions show={this.state.openModal} clickedNode={this.state.clickedNode} closeModal={this.closeModal} action={this.state.action} orgId={this.context.state.tree[0].id} /> : null
        }
        {
          this.state.revertChangesModal ?
            <ModalWindow
                show={this.state.revertChangesModal}
                onHide={this.closeRevertChangesModal}
                size={"md"}
                title={modalTitle}
                dialogClassName={'node-changes-modal'}
                modeldata={{content :modalContent}}
                footer= {modalFooter}
                onBtnClickHandler={this.onClickHandler} /> : ""
        }
          </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    globalDataTC: state.loginData.treeData,
    routeParams: state.treeviewData.routeParams,
    currentRoute: state.headerData.currentRoute,
    roleAndPermission: state.loginData.roleAndPermission,
    hideAndShowTreeView: state.treeviewData.hideAndShowTreeView,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onDropTree: (tree) => dispatch(treeBuilderActions.dropTree(tree)),
    updateExpandCollapse: (val) => dispatch(treeBuilderActions.updateExpandCollapse(val)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tree);