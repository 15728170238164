import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCog, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import NavigationItem from './navigation/navigationItem';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import Navigations from './navigation/navigations';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import './index.css';
import OpenConfigPropModal from './modal/openConfigPropModal';
import OpenNotificationTemplateModal from './modal/notificationTemplate';
import OpenAboutModal from './modal/aboutModal';
import OpenUpdateProfileModal from './modal/openUpdateProfile';
import OpenChangePasswordModal from './modal/openChangePasswordModal';
import Logout from './modal/logout';
import OpenEmailSubscriptionModal from './modal/openEmailSubscriptionsModal'
import CustomReportNavigation from './navigation/customReportsNav';

class Header extends Component {
    render() {
        const { roleId, samlEnabled, ssoEnabled } = this.props.roleAndPermission
        return (
            <Navbar variant="dark" fixed="top" className="width-100">
                <Navbar.Brand>
                    <div className="logo"></div>
                </Navbar.Brand>
                <Nav>
                    <Navigations />
                </Nav>
                <Nav className="mr-auto rightSideMenu"><CustomReportNavigation/></Nav>
                <Nav className="rightSideMenu">
                    <NavDropdown disabled={ this.props.roleAndPermission?.licenseData?.daysRemaining <= 0 } alignRight title={<span><FontAwesomeIcon icon={faCog} size="lg" /> <FontAwesomeIcon icon={faChevronDown} size="sm" /> </span>} id="collasible-nav-dropdown" className="nav-dropdown-menu">
                        <NavigationItem isDisabled={roleId === 3 || roleId === 4 ? true : false } link='/deviceGateway'><FormattedMessage id={`nav.main.deviceGateway`} /></NavigationItem>
                        <NavigationItem isDisabled={roleId === 3 || roleId === 4 ? true : false } link='/systemAdmin'><FormattedMessage id={`nav.main.systemadmin`} /></NavigationItem>
                        <NavDropdown.Item disabled={roleId === 3 || roleId === 4 ? true : false } ><OpenConfigPropModal /></NavDropdown.Item>
                        <NavigationItem isDisabled={roleId === 3 || roleId === 4 ? true : false } link='/userManagement'><FormattedMessage id={`nav.main.usermanagement`} /></NavigationItem>
                        <NavigationItem link='/licenseManagement'><FormattedMessage id={`nav.main.licensemanagement`} /></NavigationItem>
                        <NavigationItem isDisabled={roleId === 3 || roleId === 4 ? true : false } link='/auditTrail'><FormattedMessage id={`nav.main.audittrail`} /></NavigationItem>
                        <NavDropdown.Item disabled={roleId === 3 || roleId === 4 ? true : false }><OpenNotificationTemplateModal /></NavDropdown.Item>
                        <NavigationItem isDisabled={roleId === 3 || roleId === 4 ? true : false } link='/equipmentProfile'><FormattedMessage id={`nav.main.equipmentprofile`} /></NavigationItem>
                        <NavDropdown.Item ><OpenAboutModal /></NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown alignRight title={<span><FontAwesomeIcon icon={faUser} size="lg" /> <FontAwesomeIcon icon={faChevronDown} size="sm" /> </span>} id="collasible-nav-dropdown1" className="nav-dropdown-menu update-profile-menu">
                        <NavDropdown.Item className={samlEnabled ? "d-none" : ""} ><OpenUpdateProfileModal /></NavDropdown.Item>
                        <NavDropdown.Item ><OpenEmailSubscriptionModal /></NavDropdown.Item>
                        <NavDropdown.Item className={samlEnabled || ssoEnabled ? "d-none" : ""} ><OpenChangePasswordModal /></NavDropdown.Item>
                        <NavDropdown.Item ><Logout /></NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        roleAndPermission: state.loginData.roleAndPermission
    }
}

export default withRouter(connect(mapStateToProps, {})(Header));