import React, { Component } from 'react';
import EventPrecursorFilter from '../components/eventPrecursorFilter';
import { Row, Col } from 'react-bootstrap';
import { EventPrecursorContext } from '../provider/eventPrecursorProvider';
import EventPrecursorTable from '../components/eventPrecursorTable';
import EventPrecursorChart from '../components/eventPrecursorChart';
import Loader from '../../../../hoc/loader';
import AddEventPrecursorModal from '../components/addEventPrecursorModal';
import ViewEventPrecursorModal from '../components/viewEventPrecursorModal';
import NoDataAvailableComponent from '../../../../hoc/noDataAvailableComponent';
import AlertMessage from '../../../../hoc/alert';
import EventsBreadcrumbs from '../../../../hoc/eventsBreadcrumbs'
class EventPrecursorContainer extends Component {
    static contextType = EventPrecursorContext;
    render() {
        return (
            <React.Fragment>
                <Row className="msg-succ d-flex justify-content-center">
                    {this.context.state.message.type === "success" ? <AlertMessage type={this.context.state.message.type} timer={10000} message={this.context.state.message.msg} setVisible={() => this.context.onCloseAlertMessage()} /> : null}
                </Row>
                <Row className='m-0 mb-2'>
                    <EventsBreadcrumbs updatedTreeData={this.context.state.updatedTreeData} nodeData={this.context.state.treeNode} handleOnClick={this.context.loadData} />
                </Row>
                <Row className='m-0 mb-2'>
                    <Col className='p-0'>
                        <EventPrecursorFilter />
                    </Col>
                </Row>
                <Row className='m-0 mb-2 border-1px vh-45'>
                    <Col>
                        {!this.context.state.tableLoader ? this.context.state.eventPreCursorTableData.length > 0 ? <EventPrecursorTable /> : <NoDataAvailableComponent /> : <Loader elementClass="tree-ajax-loader center-element" />}
                    </Col>
                </Row>
                <Row className='m-0 mb-2 border-1px vh-45'>
                    <Col className='pt-3'>
                        {!this.context.state.chartLoader ? <EventPrecursorChart data={this.context.state} context={this.context} /> : <Loader elementClass="tree-ajax-loader center-element" />}
                    </Col>
                </Row>
                <Row>
                    {this.context.state.showModal ? this.context.state.actionValue === "add" ? <AddEventPrecursorModal /> : this.context.state.actionValue === "view" ? <ViewEventPrecursorModal /> : null : null}
                </Row>
            </React.Fragment>

        )
    }
}

export default EventPrecursorContainer;