import { getURL } from '../../../../providers/configProvider';
import { requestPOST, requestGET } from '../../../../services/extendedService';

export const getNodeData = (params) => {
  let url = getURL('insights', 'getTableData', params);
  let arr = url.split(/[? &]/).filter(e => !e.includes(null));
  url = arr[0] + "?" + arr.slice(1).join("&");
  return requestGET(url)
}
export const viewEventPrecursor = (params) => {
  const url = getURL('insights', 'view', params);
  return requestGET(url)
}
export const addEventPrecursor = (params, reqBody) => {
  const url = getURL('insights', 'add', params);
  return requestPOST(url, reqBody)
}
export const eventPrecursorChartData = (params) => {
  const url = getURL('insights', 'chartData', params);
  return requestGET(url)
}
export const eventPrecursorMapData = (params) => {
  const url = getURL('insights', 'mapData', params);
  return requestGET(url)
}
export const downloadFile = (params) => {
  let url = getURL('insights', 'download', params);
  let arr = url.split(/[? &]/).filter(e => !e.includes(null));
  url = arr[0] + "?" + arr.slice(1).join("&");
  return requestGET(url)
}