import React, { Component } from 'react';
import * as siteUtility from '../../../../utility/siteUtility';
import { getNodePath } from '../../../../services/utilityService';
import { getSites, postGroundFaultThreshold,getFilterList } from '../services/siteService';
import { FormattedMessage } from 'react-intl';
import { numberOnlyRegex } from '../../../../filters/commonFunction';
export const SiteContext = React.createContext();


class SiteProvider extends Component {
  state = {
    treeNode: this.props.treeNode,
    options: {
      ...siteUtility.tableOptions,
    },
    sites: null,
    loading: false,
    modal: {
      showModal: false,
      title: "",
      modalClose: "",
      modeldata: {},
      footer: []
    },
    selectedSiteIds: [],
    selectedSiteData: {},
    selectAll: false,
    globalGroundFaultThresholdValue : "",
    formError:{
      groundFaultThreshold : false
    },
    alertMessage:{
      msg:"",
      type:""
    },
    disableSaveBtn: true
  }
  fetchSiteList = async (treeNode, bool) => {
    this.getFilterDataList();
    this.setState({ loading: true }, () => {
      const rootParams = treeNode.type === "ROOTNODE" ? ["ROOTNODE", treeNode.id] : treeNode.routeParams;
      const params = { 'NODEPATH': getNodePath(rootParams, false), 'PAGENO': siteUtility.tableOptions.pageNo, 'PAGESIZE': siteUtility.tableOptions.pageSize, 'apiType': 'API' };
      const reqBody = this.getSelectedFilters();
      getSites(params, reqBody)
        .then(data => {
          if (data?.message === 'data canceled') {
            this.setState({ loading: false })
          } 
          else if(Array.isArray(data)){
            let lSites = { ...this.state.sites }
            lSites = data;
            this.setState({
              sites: lSites,
              loading: false,
              selectedSiteIds: [],
              selectedSiteData: {},
              selectAll: false
            });
          }
          else{
            this.setState({
              sites: data?.data,
              loading: false,
              selectedSiteIds: [],
              selectedSiteData: {},
              selectAll: false
            });
          }
        }).catch(err => {
        });
    });
  }

  getFilterDataList = async () => {
    const filterList = await getFilterList({ apiType: "API" });
    if (filterList) {
      Object.keys(filterList).forEach(key => {
        siteUtility.tableOptions.customFilterColumns.forEach(filter => {
          if (filter.type === "dropdown" && filter.dataField === key) {
              filter.data = filterList[key].sort((a, b) => {
                let x = a.toUpperCase(),
                  y = b.toUpperCase();
                return x === y ? 0 : x > y ? 1 : -1;
              });
          }
        })
      })
    }
  }

  getSelectedFilters = () => {
    let selectedFilters = {
      'deviceTypes': [],
      'groundFaultThresholds': [],
    };

    Object.keys(selectedFilters).forEach(key => {
      siteUtility.tableOptions.customFilterColumns.forEach(filter => {
         if (filter.type === "dropdown" && key.toLowerCase().indexOf(filter.dataField.toLowerCase()) !== -1 && filter.selectedItems.length > 0) {
          filter.items = filter.selectedItems;
          filter.selectedItems.forEach(item => {
            selectedFilters[key].push(item.value);
          })
        }
      });
    });


    return selectedFilters;
  }

  editSite = (pVal) => {
    if(pVal === undefined){
      pVal = {
        groundFaultThreshold : null
      }
    }
    let alertObj = {...this.state.alertMessage};
    if (!this.state.formError.groundFaultThreshold || pVal.groundFaultThreshold !== "") {
      alertObj.msg = "";
      alertObj.type = '';
    }
    this.setState({
       selectedSiteData: pVal,
       alertMessage : alertObj,
       disableSaveBtn: true
    }, () => {
      this.setState({
        modal: {
          showModal: true,
          title: pVal.site ? pVal.site : null,
          modalClose: this.modalClose,
        }
      });
    });
  }

  handleChangeGroundFaultThreshold = (pval) => {
    let lSelectedSiteData = { ...this.state.selectedSiteData };
    lSelectedSiteData.groundFaultThreshold = pval;
    this.setState({
      selectedSiteData: lSelectedSiteData,
      disableSaveBtn: false
    }, () => {
      this.validateGroundFaultThreshold(pval)
     // this.editSite(this.state.selectedSiteData);
    });
  }

  validateGroundFaultThreshold = (pval) => {
    const lFormError = { ...this.state.formError };
    if (pval === "" || !(numberOnlyRegex.test(pval)) || pval < 1 || pval > 10000) {
      lFormError.groundFaultThreshold = true;
    } else {
      lFormError.groundFaultThreshold = false;
    }
    this.setState({
      formError: lFormError
    });
  }

  closeAlertMessage = () => {
    this.setState({ alertMessage: { msg: "", type: "" } })
  }

  modelClickHandler = (type, props) => {
    if (type === "modalClose") {
      this.modalClose();
    } else if (type === "saveSingleSite") {
      this.updateSelectedSite();
    }
  }

  updateSelectedSite = () => {
    if (!this.state.formError.groundFaultThreshold) {
      let lSiteIds = this.state.selectedSiteIds.length > 0 ? [...this.state.selectedSiteIds] : [this.state.selectedSiteData.siteId];
      let editReq = {
        siteIds: lSiteIds,
        groundFaultThresholdValue: this.state.selectedSiteData.groundFaultThreshold
      };
      let params = { apiType: "API" };
      this.modalClose();
      this.setState({ loading: true }, () => {
        postGroundFaultThreshold(params, editReq).then(data => {
          this.fetchSiteList(this.props.treeNode);
        })
      });
    } else {
      this.setState({
         alertMessage: { msg: !this.state.selectedSiteData.groundFaultThreshold ? <FormattedMessage id='manage.sites.message.gft.mandatory' /> : <FormattedMessage id='db.common.pleaseCorrectTheErrors' />, type: 'danger' }
      },()=>{
        let lModal = {...this.state.modal};
        lModal.showModal= true
        this.setState({
          modal:lModal
        })
      });
    }
  }

  modalClose = () => {
    let lModal = { ...this.state.modal };
    const lFormError = { ...this.state.formError };
    lModal.showModal = false;
    lFormError.groundFaultThreshold = false;
    this.setState({
      modal: lModal,
      formError: lFormError
    })
  }

  onCBChangeHandler = (e, row) => {
    if (e.target.checked) {
      this.setState({ selectedSiteIds: this.state.selectedSiteIds.concat(row.original.siteId) },()=>{
        if(this.state.sites.length === this.state.selectedSiteIds.length){
          this.setState({  selectAll: true });
        }
      });
    } else {
      let lSelectedSiteIds = [...this.state.selectedSiteIds];
      lSelectedSiteIds.splice(lSelectedSiteIds.indexOf(row.original.siteId), 1);
      this.setState({ selectedSiteIds: lSelectedSiteIds,selectAll: false});
    }
  }

  handleSelectAll = (e) => {
    if (e.target.checked) {
      let arr = this.state.sites.map(e=>e.siteId);
      this.setState({
        selectAll: true,
        selectedSiteIds : arr
      });
    } else {
      this.setState({
        selectAll: false,
        selectedSiteIds : []
      });
    }
  }

  componentDidMount() {
    if (this.props.treeNode && Object.keys(this.props.treeNode).length > 0 && (!this.props.treeLoader)) {
      !this.props.dashboardFlag && this.fetchSiteList(this.props.treeNode);
    }
  }

  componentWillUnmount(){
    siteUtility.tableOptions.customFilterColumns.forEach(filter => filter.items = []);
    this.props.dashboardFlag && this.props.setDashboardFlag()
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.treeNode && this.props.treeNode) || (prevProps.treeNode === undefined && this.props.treeNode)) {
      if (((prevProps.treeNode === undefined && this.props.treeNode) || (prevProps.treeNode['name'] !== this.props.treeNode['name']) || (prevProps.treeNode['type'] !== this.props.treeNode['type']) || (prevProps.treeNode["id"] !== this.props.treeNode["id"]))) {
        this.setState({
          treeNode: this.props.treeNode,
          loading: false,
          dashboardFlag: this.props.dashboardFlag,  //display loader on right side table area if 'dashboardFlag' is true
        }, () => !this.props.dashboardFlag && this.fetchSiteList(this.props.treeNode));
      }
    }
  }

  render() {
    return (
      <SiteContext.Provider value={{
        state: this.state,
        editSite: this.editSite,
        modelClickHandler: this.modelClickHandler,
        modalClose: this.modalClose,
        onCBChangeHandler: this.onCBChangeHandler,
        handleSelectAll: this.handleSelectAll,
        getSiteList: this.fetchSiteList,
        handleChangeGroundFaultThreshold: this.handleChangeGroundFaultThreshold,
        closeAlertMessage: this.closeAlertMessage
      }}>
        {this.props.children}
      </SiteContext.Provider>
    )
  }
}

export default SiteProvider;
export const SiteConsumer = SiteContext.Consumer;