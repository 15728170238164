
import React from 'react';
import { FormattedMessage } from 'react-intl';
import TableMultiSortNumber from '../hoc/tableMultiSortNumber';
import Tooltip from '../hoc/tooltip';
import { faExclamationTriangle,faCircle,faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SiteView from '../mainContent/insights/eventPrecursors/components/siteView/siteView';
import moment from 'moment';
function intlFormater(value) {
    return <FormattedMessage id={`${value}`} />
}
const style = {
    fontFamily: "'univers', Arial, sans-serif",
    fontSize: "1em",
    color: "#333333"
};
export const eventPrecursorData = {
    exportData: [{ label: <FormattedMessage id='common.text.download.csv' />, value: 'csv' }],
    tableOptions: {
        pageNo: 1,
        pageSize: 10,
        pageSizeOptions: [10, 20, 50, 100],
        ordering: []
    },
    viewEventPrecursorTableOptions: {
        pageNo: 1,
        pageSize: 5,
        pageSizeOptions: [5, 10, 15, 20],
        ordering: []
    },
    startTimestamp: '',
    //endTimestamp: new Date().getTime(),
    endTimestamp: new Date().setHours(23, 59, 59, 999),
    selectedZoomRange: 7,
    zoomRange: [
        { label: intlFormater('common.label.zoom.1'), value: 1 },
        { label: intlFormater('common.label.zoom.7'), value: 7, },
        { label: intlFormater('common.label.zoom.30'), value: 30 }
    ],
    tableColumnData: [
        { accessor: 'region', Header: (original) => <TableMultiSortNumber column={original} label={<Tooltip baseContent={<FormattedMessage id={"dm.column.region"} />} message={<FormattedMessage id={"dm.column.region"} />} placement={'top'} />} /> },
        { accessor: 'substation', Header: (original) => <TableMultiSortNumber column={original} label={<Tooltip baseContent={<FormattedMessage id={"dm.column.substation"} />} message={<FormattedMessage id={"dm.column.substation"} />} placement={'top'} />} /> },
        { accessor: 'feeder', Header: (original) => <TableMultiSortNumber column={original} label={<Tooltip baseContent={<FormattedMessage id={"dm.column.feeder"} />} message={<FormattedMessage id={"dm.column.feeder"} />} placement={'top'} />} /> },
        {
            accessor: 'feedersite', Header: (original) => <TableMultiSortNumber column={original} label={<Tooltip baseContent={<FormattedMessage id={"events.eventPrecursor.column.feedersite"} />} message={<FormattedMessage id={"events.eventPrecursor.column.feedersite"} />} placement={'top'} />} />,
            Cell: (original) => {
                return <SiteView data={original} />
            }
        },
        { accessor: 'phase', Header: (original) => <TableMultiSortNumber column={original} label={<Tooltip baseContent={<FormattedMessage id={"monitor.label.phase"} />} message={<FormattedMessage id={"monitor.label.phase"} />} placement={'top'} />} />, Cell: (original) => (original.row.phase) && (original.row.phase).split('').join() },
        {
            accessor: 'current_status', width:180, Header: (original) => <TableMultiSortNumber column={original} label={<Tooltip baseContent={<FormattedMessage id={"events.eventPrecursor.column.currentStatus"} />} message={<FormattedMessage id={"events.eventPrecursor.column.currentStatus"} />} placement={'top'} />}
            />, Cell: (row) => (
                `${row.value}` === "Alert" ? <span><Tooltip baseContent={<FontAwesomeIcon icon={faExclamationTriangle} size="sm" style={{ 'color': '#e20f00' }} />} message={"Alert"} placement={'top'} /></span> :
                    `${row.value}` === "Warning" ? <span><Tooltip baseContent={<FontAwesomeIcon icon={faExclamationTriangle} size="sm" style={{ 'color': '#ffca28' }} />} message={"Warning"} placement={'top'} /></span> :
                        `${row.value}` === "Watch" ? <span><Tooltip baseContent={<FontAwesomeIcon icon={faExclamationTriangle} size="sm" style={{ 'color': '#0288d1' }} />} message={"Watch"} placement={'top'} /></span> :
                            <span><Tooltip baseContent={<FontAwesomeIcon icon={faCircle} size="lg" style={{ 'color': '#508D4E','font-size':'2.2vh' }} />} message={"Good"} placement={'top'} /></span>
            )
        },
        { accessor: 'status_datetime_utc', Header: (original) => <TableMultiSortNumber column={original} label={<Tooltip baseContent={<FormattedMessage id={"events.eventPrecursor.column.statusDate"} />} message={<FormattedMessage id={"events.eventPrecursor.column.statusDate"} />} placement={'top'} />} />, Cell: (original) =>  original.row.status_datetime_utc && moment.utc(original.row.status_datetime_utc).local().format('MM/DD/YYYY HH:mm')},
        { accessor: 'probable_cause_likelihood', width:200, Header: (original) => <TableMultiSortNumber column={original} label={<Tooltip baseContent={<FormattedMessage id={"events.eventPrecursor.column.probableCause"} />} message={<FormattedMessage id={"events.eventPrecursor.column.probableCause"} />} placement={'top'} />} /> },
    ],
    viewEventPrecursorTableColumn: [
        { accessor: 'date_investigated_utc', Header: (original) => <TableMultiSortNumber column={original} label={<Tooltip baseContent={<FormattedMessage id={"events.eventPrecursor.column.date_investigated"} />} message={<FormattedMessage id={"events.eventPrecursor.column.date_investigated"} />} placement={'top'} />} />, Cell: (original) => original.row.date_investigated_utc && moment.utc(original.row.date_investigated_utc).local().format('MM/DD/YYYY') },
        { accessor: 'issues_identified', Header: (original) => <TableMultiSortNumber column={original} label={<Tooltip baseContent={<FormattedMessage id={"events.eventPrecursor.column.issues_identified"} />} message={<FormattedMessage id={"events.eventPrecursor.column.issues_identified"} />} placement={'top'} />} /> },
        { accessor: 'asset_location', Header: (original) => <TableMultiSortNumber column={original} label={<Tooltip baseContent={<FormattedMessage id={"events.eventPrecursor.column.asset_location"} />} message={<FormattedMessage id={"events.eventPrecursor.column.asset_location"} />} placement={'top'} />} /> },
        { accessor: 'asset_type', Header: (original) => <TableMultiSortNumber column={original} label={<Tooltip baseContent={<FormattedMessage id={"events.eventPrecursor.column.asset_type"} />} message={<FormattedMessage id={"events.eventPrecursor.column.asset_type"} />} placement={'top'} />} /> },
        { accessor: 'equipment_id', Header: (original) => <TableMultiSortNumber column={original} label={<Tooltip baseContent={<FormattedMessage id={"events.eventPrecursor.column.equipment_id"} />} message={<FormattedMessage id={"events.eventPrecursor.column.equipment_id"} />} placement={'top'} />} /> },
        { accessor: 'location_address', Header: (original) => <TableMultiSortNumber column={original} label={<Tooltip baseContent={<FormattedMessage id={"events.eventPrecursor.column.location_address"} />} message={<FormattedMessage id={"events.eventPrecursor.column.location_address"} />} placement={'top'} />} /> },
        { accessor: 'customers_impacted', Header: (original) => <TableMultiSortNumber column={original} label={<Tooltip baseContent={<FormattedMessage id={"events.eventPrecursor.column.customers_impacted"} />} message={<FormattedMessage id={"events.eventPrecursor.column.customers_impacted"} />} placement={'top'} />} /> },
        { accessor: 'work_order_number', Header: (original) => <TableMultiSortNumber column={original} label={<Tooltip baseContent={<FormattedMessage id={"events.eventPrecursor.column.work_order_number"} />} message={<FormattedMessage id={"events.eventPrecursor.column.work_order_number"} />} placement={'top'} />} /> },
        { accessor: 'status', Header: (original) => <TableMultiSortNumber column={original} label={<Tooltip baseContent={<FormattedMessage id={"events.eventPrecursor.column.status"} />} message={<FormattedMessage id={"events.eventPrecursor.column.status"} />} placement={'top'} />} /> },
    ],
    chartOptions: {
        colors: ['#FF7474', '#5CD65C', '#7495BE'], // controls which colors are used.
        dashStyles: ['Solid', 'ShortDash', 'ShortDot'],
        dataColumns: [],
        highcharts: {
            credits: {
                enabled: false
            },
            chart: {
                alignTicks: false, //required for multiple series
                zoomType: 'xy',
                spacingTop: 0,
                spacingBottom: 4,
                spacingLeft: 0,
                reflow: true,
                resetZoomButton: {
                    theme: {
                        states: {
                            hover: {
                                fill: '#D98C14',
                                stroke: '#BF7400',
                            }
                        }
                    }
                },
            },
            legend: {
                enabled: true,
                align: 'right',
                verticalAlign: 'top',
                layout: 'vertical',
                x: 0,
                y: 50,
                symbolRadius: 0
            },
            title: {
                text: 'Average Monthly Weather Data for Tokyo',
            },
            exporting: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            navigator: {
                enabled: false
            },
            dataGrouping: {
                enabled: false,
            },
            rangeSelector: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            xAxis: {
                title: {
                    text: 'Time',
                    style: style
                },
                labels: {
                    style: style
                },
                gridLineWidth: 1,
                gridLineDashStyle: 'dot',
                type: 'datetime',
                lineColor: "#ddd",
                ordinal: false,
                events: {
                }
            },
            yAxis: [
                { // create and add the axis
                    allowDecimals: true,
                    labels: {
                        text: "Disturbance Activity",
                        style: style,
                        x: -5,
                        padding: 2,
                        align: 'right',
                        formatter: function () {
                            return parseFloat(this.value.toFixed(3))
                        }
                    },
                    title: {
                        text: "Disturbance Activity",
                        style: style,
                        useHTML: true
                    },
                    lineWidth: 1,
                    opposite: false,
                    gridLineWidth: 1,
                    gridLineDashStyle: 'dot',
                    showLastLabel: true
                },
                { // create and add the axis
                    allowDecimals: true,
                    labels: {
                        style: style,
                        x: 5,
                        padding: 2,
                        align: 'left',
                        formatter: function () {
                            return parseFloat(this.value.toFixed(3))
                        }
                    },
                    title: {
                        text: 'Precipitation',
                        style: style,
                        useHTML: true
                    },
                    lineWidth: 1,
                    opposite: true,
                    gridLineWidth: 0,
                    gridLineDashStyle: 'dot',
                    showLastLabel: true
                },
                { // create and add the axis
                    allowDecimals: true,
                    labels: {
                        style: style,
                        x: 5,
                        padding: 2,
                        align: 'left',
                        formatter: function () {
                            return parseFloat(this.value.toFixed(3))
                        }
                    },
                    title: {
                        text: 'Weather',
                        style: style,
                        useHTML: true
                    },
                    lineWidth: 1,
                    opposite: true,
                    gridLineWidth: 0,
                    gridLineDashStyle: 'dot',
                    showLastLabel: true
                },
                { // create and add the axis
                    allowDecimals: true,
                    labels: {
                        enabled: false
                    },
                    title: {
                        text: null
                    },
                    lineWidth: 0,
                    opposite: true,
                    gridLineWidth: 0,
                    gridLineDashStyle: 'dot',
                    showLastLabel: true,
                    softMin:0,
                    softMax:100
                }
            ],
            plotOptions: {
                scatter: {
                    marker: {
                        radius: 3,
                        symbol: 'circle',
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)'
                            }
                        }
                    },
                    states: {
                        hover: {
                            marker: {
                                enabled: false
                            }
                        }
                    },
                    jitter: {
                        x: 0.005
                    }
                },
                line: {
                    connectNulls: false,
                    animation: {
                        duration: 1200
                    },
                    marker: {
                        enabled: true,
                        symbol: 'circle',
                        radius: 0
                    }
                },
                series: {
                    pointWidth: 2,
                    gapUnit: 'value',
                    lineWidth: 2,
                    dataGrouping: {
                        enabled: false
                    },
                    animation: {
                        duration: 1200
                    },
                    marker: {
                        enabled: true,
                        radius: 0,
                        states: {
                            hover: {
                                radius: 2
                            }
                        }
                    }
                },
                flags: {
                    shape: "squarepin",
                    useHTML: true,
                    fillColor: "#fff",
                    states: {
                        hover: {
                            fillColor: '#666'
                        }
                    },
                    y: -40
                },
                dataGrouping: {
                    enabled: false
                }
            }
        } // high chart end
    },
    addEventPrecursorFields: [
        {
            key: 'asset_type', type: 'select', isMandatory: true, options: {
                "Underground Primary": [
                    { "label": "--select--", "value": "" },
                    { "label": "Capacitor Bank", "value": "Capacitor Bank" },
                    { "label": "Junction Cabinet", "value": "Junction Cabinet" },
                    { "label": "Switch Cabinet", "value": "Switch Cabinet" },
                    { "label": "Transformer", "value": "Transformer" },
                    { "label": "UG Cable", "value": "UG Cable" },
                    { "label": "Termination", "value": "Termination" }
                ],
                "Overhead Primary": [
                    { "label": "--select--", "value": "" },
                    { "label": "Capacitor Bank", "value": "Capacitor Bank" },
                    { "label": "Conductor", "value": "Conductor" },
                    { "label": "Crossarm", "value": "Crossarm" },
                    { "label": "Cutout", "value": "Cutout" },
                    { "label": "Lightning Arrestor", "value": "Lightning Arrestor" },
                    { "label": "Regulator", "value": "Regulator" },
                    { "label": "Insulator", "value": "Insulator" },
                    { "label": "Transformer", "value": "Transformer" },
                    { "label": "Recloser", "value": "Recloser" },
                    { "label": "Switch ", "value": "Switch " },
                    { "label": "Connector", "value": "Connector" },
                    { "label": "Jumper", "value": "Jumper" },
                ],
                "Secondary/Service": [
                    { "label": "--select--", "value": "" },
                    { "label": "Open Wire Secondary", "value": "Open Wire Secondary" },
                    { "label": "Meter Base", "value": "Meter Base" },
                    { "label": "Pedestal", "value": "Pedestal" },
                    { "label": "Service Cable", "value": "Service Cable" },
                ]
            },
        },
        { key: 'equipment_id', type: 'input', isMandatory: false, maxLength: 20 },
        { key: 'location_address', type: 'textarea', isMandatory: false },
        { key: 'customers_impacted', type: 'input', isMandatory: false },
        { key: 'work_order_number', type: 'input', isMandatory: false, maxLength: 10 },
        {
            key: 'status', type: 'select', isMandatory: true, options: [
                { "label": "--select--", "value": "" },
                { "label": "Investigated", "value": "Investigated" },
                { "label": "Work Order Created", "value": "Work Order Created" },
                { "label": "Work Order Closed", "value": "Work Order Closed" },
                { "label": "No Action Taken", "value": "No Action Taken" },
            ]
        }
    ]
}