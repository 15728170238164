import React, { Component, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import { CustomReportContext } from '../provider/customReportProvider';
import { scheduleFilterPopup, reportFrequency } from '../../../utility/customReportsUtility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import FilterWithBadge from './filterWithBadge';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { dateConvertion } from '../../../services/utilityService';
import Multiselect from 'multiselect-react-dropdown';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import MultiSelectDropdown from '../../manage/details/components/multiselectDropdownComponent';
import Loader from '../../../hoc/loader';

class ScheduleFilter extends Component {
    static contextType = CustomReportContext;
    constructor(props, context){
        super(props);
        this.state = {
            data: [],
            items: [],
            selReportId:context.state.selectedReportObj.id,
            parentDom:null
        }
    }
    
    multiselectRef =  null;
    endDateRef = null;
    componentDidMount() {
        let arr = [];
        this.context.state.usersList.map(x => {
            arr.push({ label: x.username, value: x.email })
        })
        this.setState({ data: arr })
    }

    componentDidUpdate() {
        if(this.state.selReportId !== this.context.state.selectedReportObj.id){
            this.setState({
                ...this.state,
                selReportId:this.context.state.selectedReportObj.id
            },()=>{
                if(this.multiselectRef && this.endDateRef)
                this.context.resetSchedular(this.multiselectRef,this.endDateRef)
            })
        }
    }

    onScheduleBtnClickHandler = (param) =>{
        this.context.onScheduleBtnClickHandler(param,this.multiselectRef,this.endDateRef)
    }

    onRefChange = node => {
        this.setState({
            parentDom:node
        })
    }

    render() {
        const { scheduleFilterData,scheduleFilterUserList,scheduleFilterLoader } = this.context.state;

        return (
            <Fragment>
                <FilterWithBadge
                    dropDownContent={
                        scheduleFilterPopup.filterForm.map((item, i) => {
                            return <Row key={i} className="mb-1 m-0 p-0 p-2 align-center">
                                <Col xs={item.type === "checkBox" ? 7 : 4} className="m-0 p-0">{item.label}</Col>
                                <Col xs={item.type === "checkBox" ? 5 : 8} className="m-0 p-0">
                                    {item.type === "textField" ?
                                        <Fragment>
                                            <input type="text" className="m-0 p-0 col-12 inpcls" value={scheduleFilterData.name} onChange={(e) => this.context.updateScheduleFilterFormData("name", e)} />
                                            <span className={this.context.state.displayNameErr ? "d-block text-danger" : "d-none"}>{scheduleFilterData.name.includes('/') ? <FormattedMessage id="customreports.name.splChar.validation" /> : <FormattedMessage id="customreports.name.validation" />}</span>
                                        </Fragment>
                                        : ""}
                                    {item.type === "checkBox" ?
                                        <input type="checkbox" defaultChecked={scheduleFilterData.emptyReports} onChange={(e) => this.context.updateScheduleFilterFormData("emptyReports", e)} /> : ""}
                                    {item.type === "singleSelect" ?
                                        <span className="single-select-dropdown">
                                                <MultiSelectDropdown
                                                selectedArrayList = {scheduleFilterData.frequency}
                                                optionList={[...reportFrequency]}
                                                onChange={(e) => this.context.updateScheduleFilterFormData("frequency", e)}
                                                name={""}
                                                multiple={false}
                                                includeSelectAll={false}
                                                includeFilter={false}
                                                open={false}
                                                valueKey="value"
                                                labelKey="text"
                                                keepOpen={true}
                                                placeholder="Select"
                                            /> 
                                        </span> : ""}   
                                    {item.type === "date" ?
                                        <><div className="align-center p-0 m-0" ref={this.onRefChange}>
                                            {item.fName === "startDate" ?
                                                <Fragment>
                                                    <span className="date-input-field float-left col-11 p-0 not-allowed-cursor">
                                                        <input className="col-12 p-0 inpcls disabled dat-schedule" type="text" value={scheduleFilterData[item.fName]} disabled={true} />
                                                    </span>
                                                    {(this.state.parentDom && scheduleFilterData[item.fName] !== null && scheduleFilterData[item.fName] !== undefined && scheduleFilterData[item.fName] !== "" && !scheduleFilterLoader) ?
                                                        <DateRangePicker
                                                            initialSettings={{
                                                                singleDatePicker: true,
                                                                showDropdowns: true,
                                                                startDate: scheduleFilterData[item.fName],
                                                                minYear: parseInt(moment().subtract(1, "year").format('YYYY'), 10),
                                                                maxYear: parseInt(moment().add(20, "year").format('YYYY'), 10),
                                                                minDate: moment(dateConvertion(new Date().getTime(), 'startDate', this.context.state.timezone)),
                                                                showISOWeekNumbers: true,
                                                                opens: 'left',
                                                                autoApply: true,
                                                                parentEl:this.state.parentDom ? this.state.parentDom : null
                                                            }}
                                                            onCallback={(e, picker) => this.context.updateScheduleFilterFormData(item.fName, e, picker,this.endDateRef)}>
                                                            <span className="date-icon schedule-cutom-reports"><FontAwesomeIcon icon={faCalendarAlt}  /></span>
                                                        </DateRangePicker> : ""
                                                    }
                                                </Fragment> :
                                                <Fragment>
                                                    <span className="date-input-field float-left col-11 p-0 not-allowed-cursor">
                                                        <input className="col-12 p-0 inpcls disabled dat-schedule" type="text" value={scheduleFilterData[item.fName]} disabled={true} />
                                                    </span>
                                                    {(this.state.parentDom && scheduleFilterData[item.fName] !== null && scheduleFilterData[item.fName] !== undefined && !scheduleFilterLoader) ?
                                                        <DateRangePicker
                                                            initialSettings={{
                                                                singleDatePicker: true,
                                                                showDropdowns: true,
                                                                //startDate: scheduleFilterData.startDate,
                                                                endDate:scheduleFilterData.endDate,
                                                                minYear: parseInt(moment().subtract(1, "year").format('YYYY'), 10),
                                                                maxYear: parseInt(moment().add(20, "year").format('YYYY'), 10),
                                                                minDate: moment(dateConvertion(scheduleFilterData.startDate, 'startDate', this.context.state.timezone)),
                                                                showISOWeekNumbers: true,
                                                                opens: 'left',
                                                                autoApply: true,
                                                                parentEl:this.state.parentDom ? this.state.parentDom : null
                                                            }}
                                                            ref={(ref) => (this.endDateRef = ref)}
                                                            onCallback={(e, picker) => this.context.updateScheduleFilterFormData(item.fName, e, picker)}>
                                                            <span className="date-icon schedule-cutom-reports"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                        </DateRangePicker> : ""
                                                    }
                                                </Fragment>}
                                        </div> 
                                        {item.fName === "endDate" ? <span className={this.context.state.displayEndDateErr ? "d-block text-danger" : "d-none"}><FormattedMessage id="customreports.enddate.validation" /></span> : "" }
                                        </>: ""}
                                    {item.type === "multiSelect" ?
                                        <Fragment>
                                            <Multiselect
                                                selectedValues={this.context.state.scheduleFilterData[item.fName]}
                                                options={scheduleFilterUserList}
                                                displayValue="name"
                                                onSelect={this.context.onSelectHandler}
                                                onRemove={this.context.onRemoveHandler}
                                                hidePlaceholder={true}
                                                ref={(ref) => (this.multiselectRef = ref)}
                                                customCloseIcon={<FontAwesomeIcon icon={faTimes}  />}
                                            />
                                            <span className={this.context.state.displayUsersErr ? "d-block text-danger" : "d-none"}><FormattedMessage id="customreports.users.validation" /></span>
                                        </Fragment> : ""}
                                </Col>
                            </Row>
                        })
                    }
                    onScheduleBtnClickHandler={
                      this.onScheduleBtnClickHandler
                    }
                />
               {scheduleFilterLoader ?  <Loader elementClass="custom-schedule-filter-ajax-loader" /> : ""}
            </Fragment>
        )
    }

}

export default ScheduleFilter;