    import React, { Component } from 'react';
    import { Row,Col } from 'react-bootstrap';
    import { connect } from 'react-redux';
    import { withRouter } from 'react-router-dom'
    import TreeProvider from '../treeview/provider/treeProvider';
    import Treeview from '../treeview';
    import Routes from '../routes';
    import * as headerActions from '../header/actions';
    import { updateGlobalTree, updateSearchQuery, notify, openErrorWindow, useRefreshForToken, logoutTokenHandler } from "../login/actions/loginActionsBuilder";
    import { updateTreeRefArr, updateExpandCollapse } from '../treeview/actions/treeViewActionBuilder';
    import { updateTreeDataKeyArr } from '../treeview/actions/treeViewActionBuilder';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
    import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
    import { displaySubMenu, setIntialTokenTimers } from '../services/utilityService';
    import Header from '../header';
    import ErrorHandler from '../hoc/errorHandler';
    import { FormattedMessage } from 'react-intl';
    import IdleTimer from 'react-idle-timer'
    import ModalWindow from '../hoc/modelWindow';
    import { setAuthToken, setTET } from '../login/actions/loginActionDispatch';
    import { isGreaterThanTodaysDate } from '../filters/dateHelper';
    import Loader from '../hoc/loader';

    class MainContent extends Component {
        constructor(props) {
            super(props);
            this.state = { 
                setTimeoutModal: false, 
                logoutCountDown: 10 
            };
            //takes token from redux store if exists or takes it from session storage when user refreshes the page or perform some other action
            this.token =Object.keys(this.props.authToken).length>0 ? this.props.authToken: JSON.parse(localStorage.getItem('token'));
            this.idleTimer = null;
            this.refreshInterval = null;
            //added this code setting token and TET into reduc store when user open duplicate tab
            Object.keys(this.props.authToken).length>0 ? this.props.authToken: this.props.authTokenDispatch(JSON.parse(localStorage.getItem('token')));
            !this.props.TET ? this.props.setTET(JSON.parse(localStorage.getItem('tokenExpiryTime'))):this.props.TET;
            [this.TIMEOUTIMER,this.REFRESHTIMER] = setIntialTokenTimers(this.token.expires_in);
        }
        componentDidMount() {
            if(this.token?.access_token){
                this.refreshInterval = setInterval(() => {
                    // this.idleTimer.getTotalActiveTime() > this.REFRESHTIMER ? console.log("Logout") :console.log("Token Refreshed");
                this.props.refreshHandle();
                }, this.REFRESHTIMER)
            }else{
                this.props.roleAndPermission.samlEnabled?logoutTokenHandler(true):logoutTokenHandler();
            }
        }

        updatePathName = (menuLink) => {
            if (this.props.location.pathname.includes(menuLink)) {
                this.props.location.pathname = '/licenseManagement';
                this.props.location.search = "";
                this.props.history.push('/licenseManagement');
            }
            this.props.onRouteChange(this.props.location.pathname, this.props.disableTree.includes(this.props.location.pathname.slice(1)))
        }
        handleOnIdle = (event) => {
            this.setState({setTimeoutModal:true})
            this.logoutInterval = setInterval(() => {this.setState(prevState => ({logoutCountDown: prevState.logoutCountDown-1}))},1000)
        }

        componentDidUpdate(prevProps) {
            if (Object.keys(this.props.roleAndPermission).length > 0 && Object.keys(this.props.roleAndPermission.licenseData).length > 0) {
                let mainmenuLicense = ['dashboard', 'mapView', 'manage', 'monitor', 'events', 'disturbances', 'insights'].filter(item => { if (!isGreaterThanTodaysDate(this.props.roleAndPermission.licenseData[item].expiresOn) && this.props.location.pathname.includes(item.toLowerCase())) return item })
                let manageSubmenuLicense = ['manage/summary', 'manage/details', 'manage/virtualDeviceManagement', 'manage/profiles', 'manage/site', 'manage/otapstatus', 'manage/configstatus', 'manage/classAssignment', 'manage/reports'].filter((item, key) => { if (!isGreaterThanTodaysDate(this.props.roleAndPermission.licenseData.manage.childNodes[key].expiresOn) && this.props.location.pathname.includes(item.toLowerCase())) return item })
                let eventsSubmenuLicense = ['events/register', 'events/summary', 'events/details'].filter((item, key) => { if (!isGreaterThanTodaysDate(this.props.roleAndPermission.licenseData.events.childNodes[key].expiresOn) && this.props.location.pathname.includes(item.toLowerCase())) return item })
                let insightsSubmenuLicense = ['insights/impendingOutages', 'insights/reoccurringEvents', 'insights/harmonics'].filter((item, key) => { if (this.props.roleAndPermission.licenseData.insights.childNodes && !isGreaterThanTodaysDate(this.props.roleAndPermission.licenseData.insights.childNodes[key].expiresOn) && this.props.location.pathname.includes(item.toLowerCase())) return item })

                if (mainmenuLicense.length > 0 || manageSubmenuLicense.length > 0 || eventsSubmenuLicense.length > 0 || insightsSubmenuLicense.length > 0 ) this.updatePathName(this.props.location.pathname);
            }
            //to handle the logout 
            if(!this.state.logoutCountDown){
                clearInterval(this.logoutInterval)
                this.props.roleAndPermission.samlEnabled?logoutTokenHandler(true):logoutTokenHandler();
            }
            //when access token expiry time is different from previous one or token expiry time is different from previous one
            //set from local storage or from redux store
            if((prevProps.authToken.expires_in!==this.props.authToken.expires_in)||(prevProps.TET!==this.props.TET)){
                [this.TIMEOUTIMER,this.REFRESHTIMER] = setIntialTokenTimers(this.props.authToken.expires_in);
                clearInterval(this.refreshInterval)
                this.refreshInterval = setInterval(() => {
                    this.props.refreshHandle();
                }, this.REFRESHTIMER)
            }
        }

        render() {
            if(Object.keys(this.props.roleAndPermission).length > 0){
            return (
                <React.Fragment>
                    <IdleTimer
                        ref={ref => { this.idleTimer = ref }}
                        timeout={this.TIMEOUTIMER}
                        onIdle={this.handleOnIdle}
                    />
                    <TreeProvider>
                    <Row className="p-0 m-0"><Col sm={12} className="p-0 m-0"><Header /></Col></Row>
                        <Row className="content-view" style={displaySubMenu(this.props.currentRoute, this.props.enableSubMenus) ? { top: '8vh' } : { top: '6vh' }}>
                            {
                                (this.props.openErrorWindow) ? <ErrorHandler /> : null
                            }
                            {
                                this.state.setTimeoutModal&&this.state.logoutCountDown>0?
                                <ModalWindow
                                    show={this.state.setTimeoutModal}
                                    closeButton={false}
                                    size={'lg'}
                                    dialogClassName={"timeout-dialog-modal"}
                                    title={<FormattedMessage id='logout.errorwindowhead' />}
                                    modeldata={{content: <><FormattedMessage id='logout.errorwindow'/>{this.state.logoutCountDown}</>}}
                                    footer={[ { className: "modalSaveBtn", name: <FormattedMessage id='logout.cancel.modal' />, value: "cancel" }]}
                                    onBtnClickHandler={(val)=>{clearInterval(this.logoutInterval);this.setState({setTimeoutModal:false,logoutCountDown:10})}}
                                />:
                                null
                            }
                            <div className="col-xs-2 col-2 p-0 m-0 pr-3 left-col" style={(this.props.location.pathname === '/' || this.props.disableTreeView || this.props.hideAndShowTreeView) ? { display: 'none' } : null}>
                                <Treeview />
                            </div>
                            <div className={"right-col p-0 m-0" + ((this.props.location.pathname === '/' || this.props.disableTreeView || this.props.hideAndShowTreeView) ? 'col-xs-12 col-12' : 'col-xs-10 col-10' ) } >
                            {this.props.disableTreeView || !this.props.hideAndShowTreeView ? "" :
                                <span className="onhvr-expnd-clps2" onClick={ ()=> this.props.updateExpandCollapse(false) }> <FontAwesomeIcon icon={faChevronRight} size="lg" /> </span> }
                                {Object.entries(this.props.roleAndPermission).length !== 0 && <Routes />}
                            </div>
                        </Row>  
                    </TreeProvider>
                </React.Fragment>
            );
            } else {
                return <Loader elementClass="tree-ajax-loader center-element" />
            }
        }
    }

    const mapStateToProps = state => {
        return {
            globalDataTree: state.loginData.treeData,
            currentRoute: state.headerData.currentRoute,
            disableTree: state.headerData.disableTree,
            disableTreeView: state.headerData.disableTreeView,
            notifyMe: state.loginData.notify,
            treeRefArrVal: state.treeviewData.treeRefArr,
            TreeDataKeyValuePairArr: state.treeviewData.TreeDataKeyValuePair,
            roleAndPermission: state.loginData.roleAndPermission,
            hideAndShowTreeView: state.treeviewData.hideAndShowTreeView,
            enableSubMenus: state.headerData.enableSubMenus,
            openErrorWindow: state.loginData.showErrorModal,
            authToken: state.loginData.authToken,
            TET :state.loginData.TET
        };
    }

    const mapDispatchToProps = dispatch => {
        return {
            onRouteChange: (route, isTreeDisable) => dispatch(headerActions.onRouteChange(route, isTreeDisable)),
            updateGlobalTree: (updatedTreeVal) => dispatch(updateGlobalTree(updatedTreeVal)),
            updateSearchQuery: (updatedSearchQuery) => dispatch(updateSearchQuery(updatedSearchQuery)),
            notify: (updateNotify) => dispatch(notify(updateNotify)),
            treeRefArr: (updatetreeRefArr) => dispatch(updateTreeRefArr(updatetreeRefArr)),
            treeDataKeyValArr: (updatetreeDataKeyValArr) => dispatch(updateTreeDataKeyArr(updatetreeDataKeyValArr)),
            updateExpandCollapse: (val) => dispatch(updateExpandCollapse(val)),
            openErrorModalWindow: (message, title) => dispatch(openErrorWindow(message, title)),
            refreshHandle:  ()=> dispatch(useRefreshForToken()),
            authTokenDispatch: (token) => dispatch(setAuthToken(token)),
            setTET: (tet) => dispatch(setTET(tet))
        }
    }

    export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainContent));