import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as headerActions from '../header/actions';
import EventPrecursor from '../mainContent/insights/eventPrecursors';


class InsightsRoutes extends React.PureComponent {

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        this.props.onRouteChange(this.props.location.pathname, this.props.disableTree.includes(this.props.location.pathname.slice(1)))
    }

    render() {
        return (
            <Switch>
                <Route path='/insights/impendingOutages' component={EventPrecursor} />
                <Route path='insights/reoccurringEvents' component={EventPrecursor} />
                <Route path='insights/harmonis' component={EventPrecursor} />
                <Route exact path='/insights' render={() => ( <Redirect to='/insights/impendingOutages'/> )}/>
            </Switch>
        );
    }
}

const mapStateToProps = state => {
    return {
        disableTree: state.headerData.disableTree
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onRouteChange: (route, isTreeDisable) => dispatch(headerActions.onRouteChange(route, isTreeDisable))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InsightsRoutes));