import React, { Component } from 'react';
import {Picky} from "react-picky";
import "react-picky/dist/picky.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';

class MultiSelectDropdown extends Component {
    render() {
        return (
            <Picky
                value={this.props.selectedArrayList}
                options={this.props.optionList}
                onChange={e => this.props.onChange(e, this.props.name)}
                open={this.props.open}
                numberDisplayed={1}
                valueKey={this.props.valueKey}
                labelKey={this.props.labelKey}
                multiple={this.props.multiple}
                includeSelectAll={this.props.includeSelectAll}
                includeFilter={this.props.includeFilter}
                dropdownHeight={300}
                manySelectedPlaceholder={this.props.manySelectedPlaceholder}
                allSelectedPlaceholder={this.props.allSelectedPlaceholder}
                className={this.props.className}
                placeholder={this.props.placeholder}
                keepOpen={this.props.multiple ? this.props.multiple : false}
                disabled={this.props.disabled ? this.props.disabled : false}
                id={this.props.id ? this.props.id : ""}
                render={({ isSelected, item, selectValue, labelKey, valueKey }) => {
                    return (
                        <React.Fragment key={item[valueKey]}>
                            {this.props.btmLabel && item.keyIndex === 0 ? <li className="p-0 m-0" key={item.keyIndex} onClick={this.props.handleShowAllBtn}>
                                <span className='multiselect-custom-btn'>{this.props.btmLabel} </span>
                            </li> : ""}
                            <li className={isSelected ? 'selected' : ""} key={item[valueKey]} onClick={(event) => selectValue(item) & event.nativeEvent.stopImmediatePropagation()}>
                                {isSelected ? <span><FontAwesomeIcon icon={faCheck} size="sm" />&nbsp;&nbsp;</span> : <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                                <span>{item[labelKey]}</span>
                            </li>
                        </React.Fragment>
                    )
                }}
                renderSelectAll={({ tabIndex, allSelected, toggleSelectAll, multiple }) => {
                    if (multiple && this.props.includeSelectAll) {
                        return (
                            <div
                                tabIndex={tabIndex}
                                role="option"
                                className={allSelected ? 'option selected' : 'option'}
                                onClick={toggleSelectAll}
                                onKeyPress={toggleSelectAll}
                                aria-selected={false}
                            >
                                {allSelected ? <span><FontAwesomeIcon icon={faCheck} size="sm" /> &nbsp;&nbsp;</span> : <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                                <FormattedMessage id="db.multiSelect.selectAll" />
                            </div>
                        );
                    }
                }}
            />
        );
    }
}

export default MultiSelectDropdown;

// optionList : (display all list data, type: array of abject)
                    //[{name: "state", text: "Compromised", value: "Compromised"} , {name: "state", text: "Non-Production", value: "Non-Production"} ]
//selectedArrayList : (if user selected list item, type: array of object)
                    //[{name: "state", text: "Compromised", value: "Compromised"}]
//name: "state" (field name, type: string)
//placeholder: "Select"(type: string)
//multiple: boolean
//includeSelectAll: boolean (if false 'Select all' item will not display, if true 'select all' item will display )
//includeFilter: false
// for display 'Show all' end of the list item need to pass optionList like this,
            //[{name: "state", text: "Compromised", value: "Compromised", keyIndex : 0, } , {name: "state", text: "Non-Production", value: "Non-Production", keyIndex : 1,} ]

