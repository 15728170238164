import { saveAs } from "file-saver";

// highlight search match data
export const makeSearchHighlight = (str, query) => {
    const n = str.toString().toUpperCase();
    const q = query.toUpperCase();
    const x = n.indexOf(q);
    if (!q || x === -1) {
        return str;
    }
    const l = q.length;
    return str.substr(0, x) + '<span class="makeHighlightColor">' + str.substr(x, l) + '</span>' + str.substr(x + l);
}

export const alphaNumericRegex = /^[a-zA-Z0-9\s]+$/i;  // Regex to accept alpha-numeric data

export const alphaNumericwithOptDeciRegex = /^[a-zA-Z0-9\s]+(\.\d+)*$/i;  // Regex to accept alpha-numeric data

export const numberOnlyRegex = /^[0-9]*$/; // Accept only Number

export const negativeNumberRegex = /^-?[0-9]\d*(\d+)?$/;    // Accept both positive and negative numbers

export const numWithOptDeciRgx = /^(0|[1-9]\d*)(\.\d+)?$/; // Accept only Number and decimal is optional

export const negativeNumOptRgx = /^[-]?[0-9]*$/;   // Accept only number and minus is optional

export const stringOnlyRegex = /^[A-Za-z ]+$/;   // Accept only strings with space

export const stringWithSplCharactersRegex = /^[a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]*$/; // Accepts only string, space and special characters

export const ipAddressRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/    // max 255.255.255.255

export const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,})+$/    // Domain extension (.corp, .travel, .org etc) should be minimum 2

export const passwordRegex = /^(?=.*[\W_])[A-Za-z\d\W_]{8,20}$/
/*  
    Any special character allows. At least one special character in the password. Between 8 and 20 characters in length. The password can contain uppercase letters, lowercase letters, digits, and any non-alphanumeric characters (special characters).
*/

export const getCookie = (name) => {
    let cookie = {};
    document.cookie.split(';').forEach(function (el) {
        let [k, v] = el.split('=');
        cookie[k.trim()] = v;
    })
    return cookie[name];
}

const s2ab = (s) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

export const setDownloadCSV = (response) =>{
    const dirtyFileName = response.headers['content-disposition'];
    const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
    var  fileName = dirtyFileName.match(regex)[3];
    saveAs(new Blob([response.data]), fileName)
}


/**
 * Exits fullscreen mode if the map is currently in fullscreen mode.
 * This function is called only when the map is in fullscreen, and the user clicks the StreetView link. It is calling from both the MapView and Fault Details pages.
 */

 export const exitFullscreenToNormalMode=()=> {
    if (document.exitFullscreen) {
          document.exitFullscreen();
    } else if (document.webkitExitFullscreen) { // Safari
      document.webkitExitFullscreen();
    }
}

/*
* * This function checks the user agent string to determine whether the current browser is Chrome, Safari, or another browser. 
It is specifically used to address issues with popover focus functionality that is not working as expected in Safari.
*/

export const getCurrentBrowser =()=> {
    let userAgent = navigator.userAgent;
    let browser = userAgent.includes("Chrome") ? 'chrome' : (userAgent.includes("Safari") && !navigator.webkitFoundationVersion ? 'safari' : 'others')
    return browser;
}