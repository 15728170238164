import React, { useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getCurrentBrowser } from '../filters/commonFunction';

const PopOverMessage = (props) => {
    const [showPOPOver, setshowPOPOver] = useState(false)
    const openPOPOver = (pval) => {
        setshowPOPOver(true)
    }
    const closePOPOver = (pval) => {
        setshowPOPOver(false)
    }
    return (
        <OverlayTrigger
            onEnter={openPOPOver}
            onExit={closePOPOver}
            trigger={getCurrentBrowser() === 'safari' && props.trigger === "focus" ? 'hover' : props.trigger}
            key={props.keyVal}
            placement={props.placement}
            overlay={
                <Popover id={`popover-positioned-${props.keyVal}`}>
                    <span style={{ display: showPOPOver ? 'block' : "none" }}>
                        {props.popoverBody}
                    </span>
                </Popover>
            }
        >
            <button className='btn-none'>
                {props.displayPopOver}
            </button>
        </OverlayTrigger>
    )
}

PopOverMessage.propTypes = {
    trigger: PropTypes.string,
    placement: PropTypes.string,
    popoverBody: PropTypes.element,
    displayPopOver: PropTypes.element,
}


export default PopOverMessage;

//trigger : 'hover' | 'click' |'focus' | Array<'hover' | 'click' |'focus'> 
//placement : 'auto-start' | 'auto' | 'auto-end' | 'top-start' | 'top' | 'top-end' | 'right-start' | 'right' | 'right-end' | 'bottom-end' | 'bottom' | 'bottom-start' | 'left-end' | 'left' | 'left-start'
//popover-body: html element
//displaypopover : html element(on trigger of displaypopover the popever will be displayed)