
import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { CustomReportContext } from '../provider/customReportProvider';
import { FormattedMessage } from 'react-intl';
import DateRangePick from '../components/dateRangePick';
import CustomReportsTable from '../components/customReportsTable';
import DropdownMenuCheckbox from '../../../hoc/dropdownMenuCheckbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTh, faCheckCircle, faDownload} from '@fortawesome/free-solid-svg-icons';
import CustomFilter from '../components/customFilter';
import SingleSelect from '../../../hoc/singleSelect';
import { customReportConfig } from '../../../utility/customReportsUtility';
import ScheduleFilter from '../components/scheduleFilter';
import Loader from '../../../hoc/loader';
import Tooltip from '../../../hoc/tooltip';

class CustomReportContainer extends Component {
    static contextType = CustomReportContext;

    state = {
        selectedReportObj: this.context.state.selectedReportObj
    }

    componentDidMount() {
        this.context.getReportData({});
    }

    componentDidUpdate(prevProps) {
        if (this.state.selectedReportObj.id !== this.context.state.selectedReportObj.id) {
            this.setState({
                selectedReportObj: this.context.state.selectedReportObj
            }, () => {
                this.context.getReportData({});
            })
        }
    }

    render() {
        return (
            <Container fluid className="custom-report p-0">
                <Row className="col-12 m-0 p-0">
                    <Col className="p-0"><span><FormattedMessage id={`nav.main.customreport`} /> : {this.context.state.selectedReportObj.name}</span></Col >
                </Row>
                {this.context.state.displayStartDt && this.context.state.displayEndDt && this.context.state.selectedReportObj.name !== "RMA Issues Reports" ?
                <Row className="col-12 m-0 p-0">
                    <Col className="mt-3 col-4 cr-filter p-0">
                            <DateRangePick startDate={this.context.state.loader ? null : this.context.state.displayStartDt} endDate={this.context.state.loader ? null : this.context.state.displayEndDt} onCallback={this.context.calenderCallback} /> 
                    </Col>
                    <Col className="mt-3 col-5 d-flex cr-filter p-0">
                        <CustomFilter />    
                        
                        {/* For columns dropdown section start*/}
                        <div className={this.context.state.tableData.length === 0 ? 'disabled' : ''}>
                            <DropdownMenuCheckbox
                                btnContent={ <span className="add-btn column-filter">
                                                <Tooltip 
                                                    placement='top' 
                                                    message={<FormattedMessage id={`customreports.label.columns`} />} 
                                                    baseContent={<FontAwesomeIcon icon={faTh} size="lg" />} />
                                            </span>}
                                bodyContent={this.context.state.tableColumnFilter.totalItems}
                                selectedCulumns={this.context.state.tableColumnFilter.selectedItems}
                                handleFilterChecked={this.context.setColumnStatus} />
                        </div>
                        {/* For columns dropdown section end*/}

                        <span className={`btn btnStyling ml-2 ${this.context.state.filterPercentErr ? "disabled" : ""}`} onClick={this.context.applyfilter}>
                            <Tooltip 
                                placement='top' 
                                message={<FormattedMessage id={`customreports.label.apply`} />} 
                                baseContent={ <FontAwesomeIcon icon={faCheckCircle} size="lg" />}/>
                           
                        </span>
                    </Col>
                    <Col className="mt-3 col-3 text-right p-0">
                        <span key='export' className='col-form-label p-0 d-inline-flex export-content'>
                            {/* For download dropdown section start */}
                            <span className='single-select-dropdown option-list'>
                                <SingleSelect
                                    data={customReportConfig.exportOptions}
                                    setType={this.context.onExportClick}
                                    value={<Tooltip
                                                placement='top' 
                                                message={<FormattedMessage id={`customreports.label.download`} />}
                                                baseContent={<FontAwesomeIcon icon={faDownload} />} /> }
                                    isDisplayLabel={false}
                                    disabled = {this.context.state.tableData.length === 0}
                                    />
                            </span>
                            {/* For download dropdown section end */}
                        </span>
                        <span className="ml-2 d-inline-flex">
                           <ScheduleFilter />
                        </span>
                    </Col>
                </Row>
                : // This is only for RMA Issues Reports
                <Row className="col-12 m-0 p-0">
                    <Col className="mt-3 col-6 d-flex cr-filter p-0">
                        <CustomFilter />    
                        
                        {/* For columns dropdown section start*/}
                        <div className={this.context.state.tableData.length === 0 ? 'disabled' : ''}>
                            <DropdownMenuCheckbox
                                btnContent={ <span className="add-btn column-filter">
                                                <Tooltip 
                                                    placement='top' 
                                                    message={<FormattedMessage id={`customreports.label.columns`} />} 
                                                    baseContent={<FontAwesomeIcon icon={faTh} size="lg" />} />
                                            </span>}
                                bodyContent={this.context.state.tableColumnFilter.totalItems}
                                selectedCulumns={this.context.state.tableColumnFilter.selectedItems}
                                handleFilterChecked={this.context.setColumnStatus} />
                        </div>
                        {/* For columns dropdown section end*/}

                        <span className={`btn btnStyling ml-2 ${this.context.state.filterPercentErr ? "disabled" : ""}`} onClick={this.context.applyfilter}>
                            <Tooltip 
                                placement='top' 
                                message={<FormattedMessage id={`customreports.label.apply`} />} 
                                baseContent={ <FontAwesomeIcon icon={faCheckCircle} size="lg" />}/>
                           
                        </span>
                    </Col>
                    <Col className="mt-3 col-6 text-right p-0">
                        <span key='export' className='col-form-label p-0 d-inline-flex export-content'>
                            {/* For download dropdown section start */}
                            <span className='single-select-dropdown option-list'>
                                <SingleSelect
                                    data={customReportConfig.exportOptions}
                                    setType={this.context.onExportClick}
                                    value={<Tooltip
                                                placement='top' 
                                                message={<FormattedMessage id={`customreports.label.download`} />}
                                                baseContent={<FontAwesomeIcon icon={faDownload} />} /> }
                                    isDisplayLabel={false}
                                    disabled = {this.context.state.tableData.length === 0}
                                    />
                            </span>
                            {/* For download dropdown section end */}
                        </span>
                    </Col> 
                </Row>}
                <Row className="col-12 m-0 p-0">
                    <Col className="mt-3 p-0 col-12">
                        {/* <CustomReportsTable /> */}
                        {this.context.state.loader ? <div className="loader-wrap"><Loader elementClass="custom-report-ajax-loader" /> </div> : <CustomReportsTable />}
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default CustomReportContainer;