import moment from 'moment-timezone';
import jstz from 'jstz';

export const groupRouteParams = (requestParams, routeParams) => {
    if (routeParams instanceof Array) {
        for (var i = 0; i < routeParams.length; i = i + 2) {
            requestParams[routeParams[i]] = routeParams[i + 1];
        }
        requestParams['apiType'] = routeParams[routeParams.length - 2];
    }
    return requestParams;
};

// bool --> This parameter is used for events page where "s" should not be added for the node type in the path
export const getNodePath = (params, caption, bool, view) => {
    let path = "";
    if (params instanceof Array) {
        for (var i = 0; i < params.length; i = i + 2) {
            let nodeType = ((params[i].replace(/_/g, '').toLowerCase() === 'lateralsite') && (caption)) ? 'lateralSite' : params[i].replace(/_/g, '').toLowerCase();
            nodeType = (view && (view === 'mapView') && (nodeType === 'site')) ? nodeType : bool ? nodeType : nodeType + 's';
            path = ((params[i] === "ROOTNODE") && (typeof (params[i + 1]) === "object")) ? path.concat(params[i + 1]["id"]) : (params[i] === "ROOTNODE") ?  path.concat(params[i + 1]) : path.concat("/" + nodeType + "/" + params[i + 1]["name"]);
        }
    }
    return path;
}

//custom sorting based on the value passed ("name/id")
export const compareValues = (type) => {
    return (v1, v2) => {
        var t1 = typeof v1[type];
        var t2 = typeof v2[type];
        if (t1 === t2) {
            if (t1 === "string") {
                v1 = v1[type].toLowerCase();
                v2 = v2[type].toLowerCase();
            }
            if (v1 === v2) { return 0; }
            return v1.localeCompare(v2, 'en', { sensitivity: 'base' });
        } else {
            return t1.localeCompare(t2, 'en', { sensitivity: 'base' });
        }
    };
};

export const sortFirmwareversions = function (a, b) {
    var i, diff;
    var regExStrip0 = /(\.0+)+$/;
    var segmentsA = a.replace(regExStrip0, '').split('.');
    var segmentsB = b.replace(regExStrip0, '').split('.');
    var l = Math.min(segmentsA.length, segmentsB.length);

    for (i = 0; i < l; i++) {
        diff = parseInt(segmentsA[i], 10) - parseInt(segmentsB[i], 10);
        if (diff) {
            return diff;
        }
    }
    return segmentsA.length - segmentsB.length;
};

export const numberToDate = (date) => {
    var monthNames = { "01": "Jan", "02": "Feb", "03": "Mar", "04": "Apr", "05": "May", "06": "Jun", "07": "Jul", "08": "Aug", "09": "Sep", "10": "Oct", "11": "Nov", "12": "Dec" };
    var dateArray = date.toString().split("");
    var monthNo = dateArray.slice(4, 6).join("");
    var month = monthNames[monthNo];
    return dateArray.slice(6, 8).join("") + " " + (month ? month : monthNo);
    // return $filter('dateConvertion')(new Date(dateArray.slice(4, 6).join("") + "-" + dateArray.slice(6, 8).join("") + "-" + dateArray.slice(0, 4).join("")), 'day');
};

export const getDateConvertedString = (inputData) => {
    const timeZone = jstz.determine().name();
    let browserTime = moment.tz(inputData, timeZone);
    browserTime = browserTime.format("MM/DD/YYYY hh:mm:ss A");
    return inputData === null ? "Unavailable" : browserTime;
}

export const fetchBrowserTimeZone = (type, serverTimeZone) => {
    let browserTimezone = (serverTimeZone) ? serverTimeZone : jstz.determine().name();
    if (type !== "longValue") {
        browserTimezone = ' (' + moment.tz(browserTimezone).zoneAbbr() + ') ';
    }
    return browserTimezone;
}

export const getBrowserTimeZone = (serverTimeZone) => {
    return fetchBrowserTimeZone("longValue", serverTimeZone);
}

export const dateConvertion = (inputData , format, zone) => {
    let  timeZone = (!zone) ? getBrowserTimeZone() : zone;
    if ((format !== 'offsetTimestamp') && (typeof(inputData) === 'string')) {
        var str = parseInt(inputData, 10);
        inputData = inputData !== str ? moment.utc(inputData).toDate() : str;
    }
    let browserTime = moment.tz(inputData, timeZone);
    if(format === 'endDate') {
        browserTime = browserTime.set({hour:23,minute:59,second:59,millisecond:999}).valueOf();
    } else if(format === 'now') {
        browserTime = browserTime.valueOf();
    } else if(format === 'offsetTimestamp') {
        var offsetStr = moment().tz(timeZone).format().slice(-6);
        let Dst = moment(inputData,"MM/DD/YYYY hh:mm:ss.SSS").tz(timeZone).isDST();
        if(Dst)
        browserTime =  moment(inputData+" "+offsetStr, "MM/DD/YYYY hh:mm:ss:SSS Z").add(-1, 'hours').valueOf();
		else
        browserTime = moment(inputData+" "+offsetStr, "MM/DD/YYYY hh:mm:ss:SSS Z").valueOf();

    } else if(format === 'short') {
        browserTime = browserTime.format("MM/DD/YYYY");
    } else if(format === 'shortDate') {
        browserTime = browserTime.format("MM/DD/YY");
    } else if(format === 'longDate') {
        browserTime = browserTime.format("MM/DD/YYYY hh:mm:ss A");
    } else if(format === 'maxLongDate') {
        browserTime = browserTime.format("MM/DD/YYYY hh:mm:ss.SSS A");
    }else if(format === 'maxLongDateWithoutAP') {
        browserTime = browserTime.format("MM/DD/YYYY hh:mm:ss.SSS");
    }else if(format === 'AMPM') {
        browserTime = browserTime.format("A");
    } else if(format === 'day') {
        browserTime = browserTime.format("DD MMM");
    } else if(format === "time") {
        browserTime = browserTime.format("HH:mm:ss");
    }else if(format === 'timeWithAP') {
        browserTime = browserTime.format("hh:mm:ss A");
    }else if(format === 'serviceFormat') {
        browserTime = browserTime.format("YYYY-MM-DD HH:mm:ss");
    }else if(format === 'millsTimeWithAP') {
        browserTime = browserTime.format("hh:mm:ss.SSS A");
    } else {
        browserTime = browserTime.format("MM/DD/YYYY hh:mm A");
    }
    return inputData === null ? '' : browserTime;
}

export const groupingSelectedRows = (array, keys) => {
    let grouped = {};
    array.forEach(item => {
        return keys.reduce((res, val, i) => {
            res[item[val]] = res[item[val]] || (i + 1 === keys.length ? [] : {});
            return res[item[val]];
        }, grouped).push(item);
    });
    return grouped;
};

export const userPreferences = {
    tempUnit: 'F',
    treeOrder: 'Custom'
};

export const getTempUnit = (str) => {
    let unit = '';
    unit = str === 'value' ? userPreferences.tempUnit : userPreferences;
    return unit;
};

export const displaySubMenu = (path, subMenus) => {
    for (let i = 0; i < subMenus.length; i++) {
        if (path.indexOf(subMenus[i]) > -1) return true;
    }
}

export const getTokenExpiryTime = (prop) => { //used to set token expiry time into redux store
    return new Date().getTime() + prop*1000
}

export const setIntialTokenTimers = (props) => { //used to set token expiry time into local storage and start initial timers
    !JSON.parse(localStorage.getItem('tokenExpiryTime'))? localStorage.setItem('tokenExpiryTime', new Date().getTime() + JSON.parse(localStorage.getItem('token')).expires_in*1000):''
    const TIMEOUTIMER =  props*1000; //idle time is set for 15 minutes
    const REFRESHTIMER = JSON.parse(localStorage.getItem('tokenExpiryTime'))? JSON.parse(localStorage.getItem('tokenExpiryTime')) - new Date().getTime() - 60000 :(props*1000)-60000; // token refresh time is set from token api expiry response
    return [TIMEOUTIMER, REFRESHTIMER];
}