import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { eventPrecursorData } from '../../../../utility/eventPrecursorUtility';
import { closeUnusedTreeNodes } from '../../../../utility/faultAnalysisUtility';
import { dateConvertion, getNodePath } from '../../../../services/utilityService';
export const EventPrecursorContext = React.createContext();
import { getNodeData, eventPrecursorChartData, downloadFile } from '../services/eventPrecursorService';
import { setDownloadCSV } from '../../../../filters/commonFunction';
import moment from 'moment-timezone';

class EventPrecursorProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      totalDevicesCount: 0,
      selectedZoomRange: eventPrecursorData.selectedZoomRange,
      zoomRange: eventPrecursorData.zoomRange,
      treeNode: this.props.treeNode,
      updatedTreeData: this.props.updatedTreeData,
      roleAndPermission: this.props.roleAndPermission,
      startTimestamp: dateConvertion(eventPrecursorData.endTimestamp - (1000 * 3600 * 24), 'endDate', null) + 1 - (7 * 1000 * 60 * 60 * 24),
      endTimestamp: eventPrecursorData.endTimestamp,
      eventPreCursorTableData: [],
      eventPreCursorChartData: [],
      epTableSelectedRow: {},
      epTableSelectedRowId: '',
      escalationTypeDD: {
        options: [{ name: "Alert", text: "Alert", value: "Alert", keyIndex: 0 }, { name: "Warning", text: "Warning", value: "Warning", keyIndex: 1 }, { name: "Watch", text: "Watch", value: "Watch", keyIndex: 2 }, { name: "Good", text: "Good", value: "Good", keyIndex: 3 }].sort((a, b) => a.name.localeCompare(b.name)),
        selected: [{ name: "Alert", text: "Alert", value: "Alert", keyIndex: 0 }, { name: "Warning", text: "Warning", value: "Warning", keyIndex: 1 }, { name: "Watch", text: "Watch", value: "Watch", keyIndex: 2 }],
      },
      chartLoader: false,
      tableLoader: false,
      showModal: false,
      actionValue: "",
      totalBadgeCount: 1,
      chartFinalEndTimestamp: eventPrecursorData.endTimestamp,
      chartEndTimestamp: eventPrecursorData.endTimestamp,
      chartStartTimestamp: new Date(eventPrecursorData.endTimestamp).setHours(0, 0, 0, 0) - (30 * 1000 * 60 * 60 * 24),
      message: {
        status: false,
        msg: "",
        type: ""
      },
      disableFilterReset:true
    }
  }

  calenderCallback = (start, end) => {
    this.setState({
      ...this.state,
      startTimestamp: moment(new Date(start)), endTimestamp: moment(new Date(end)),
      chartFinalEndTimestamp : new Date(end).setHours(23, 59, 59, 999),
      chartEndTimestamp :new Date(end).setHours(23, 59, 59, 999),
      chartStartTimestamp:new Date(end).setHours(0, 0, 0, 0) - (30 * 1000 * 60 * 60 * 24)
    }, () => {
      eventPrecursorData.tableOptions.pageNo = 1;
      this.getEventPrecursorTableData();
    })
  }

  componentDidMount() {
    let lValue = this.state.treeNode.type === "ROOTNODE" ? [this.state.treeNode.id] : getNodePath(this.state.treeNode.routeParams, true, true);
    if (lValue) {
      window.history.replaceState(null, null, location.origin + location.pathname + `?node=ROOTNODE/${lValue}`)
    }
    eventPrecursorData.tableOptions.ordering = [];
    !this.props.dashboardFlag && this.getEventPrecursorTableData();
  }
  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.treeNode, ["id", "name", "type"]) !== JSON.stringify(prevProps.treeNode, ["id", "name", "type"])) {
      !this.props.dashboardFlag && this.dashboardFlagCheckLoadData()
    }
  }
  dashboardFlagCheckLoadData = () => {
    this.setState({
      chartEndTimestamp: new Date(this.state.endTimestamp).setHours(23, 59, 59, 999),
      chartStartTimestamp: new Date(this.state.endTimestamp).setHours(0, 0, 0, 0) - (30 * 1000 * 60 * 60 * 24),
    });
    this.getEventPrecursorTableData();
    this.props.setDashboardFlag();
  }
  static getDerivedStateFromProps(nextProps) {
    return {
      treeNode: nextProps.treeNode,
      treeLoading: nextProps.treeLoader,
      selectSearchedNode: nextProps.selectSearchedNode
    };
  }
  handleRowOnClick = (row) => {
    if (row.event_id != this.state.epTableSelectedRow.event_id) {
      this.setState({
        epTableSelectedRow: row,
        chartLoader: true,
        chartEndTimestamp: new Date(this.state.endTimestamp).setHours(23, 59, 59, 999),
        chartStartTimestamp: new Date(this.state.endTimestamp).setHours(0, 0, 0, 0) - (30 * 1000 * 60 * 60 * 24),
      }, () => {
        this.getEventPrecursorChartData();
      });
    }
  }
  onValChange = (value) => {
    this.setState({
      escalationTypeDD: {
        ...this.state.escalationTypeDD,
        selected: value
      }
    });
  }
  updateActionValue = (pValue) => {
    this.setState({
      showModal: true,
      actionValue: pValue,
      message: {
        status: false,
        msg: "",
        type: ""
      }
    })
  }
  closeModal = () => {
    this.setState({
      showModal: false,
      actionValue: ""
    })
  }
  closeAlertMessage = () => {
    this.setState({
      message: {
        status: false,
        msg: "",
        type: ""
      }
    })
  }
  getEventPrecursorTableData = (treeNode = this.props.treeNode) => {
    this.setState({
      tableLoader: true,
      chartLoader:true
    }, () => {
      const params = {
        "hierid": treeNode.type !== "ROOTNODE" ? treeNode.type === "REGION" ? treeNode.routeParams[3]?.id : treeNode.routeParams[5]?.id : null,
        "feederid": treeNode.type === "FEEDER" ? treeNode.routeParams[7]?.id : null,
        "PAGENO": eventPrecursorData.tableOptions.pageNo,
        "PAGESIZE": eventPrecursorData.tableOptions.pageSize,
        "ESCALATIONTYPE": this.state.escalationTypeDD.selected.length > 0 ? this.state.escalationTypeDD.selected.map(e => e.name).toString() : this.state.escalationTypeDD.options.map(e => e.name).toString(),
        "ENDTIMESTAMP": encodeURIComponent(moment(moment(this.state.endTimestamp)).utc().format('YYYY-MM-DD HH:mm:ss:SSS')),
        "ORDERING": eventPrecursorData.tableOptions.ordering.length > 0 ? eventPrecursorData.tableOptions.ordering.map(e => e.desc ? `-${e.id}` : `${e.id}`).toString() : null,
        'apiType': "data"
      };
      getNodeData(params)
        .then(data => {
          if (data) {
            this.setState({
              totalDevicesCount: data.results_count,
              eventPreCursorTableData: data.results ? data.results : [],
              epTableSelectedRow: data.results ? data.results[0] : {},
              eventPreCursorChartData: [],
              tableLoader: false,
              chartLoader: data.results && data.results.length > 0 ? true : false
            }, () => {
              data.results && data.results.length > 0 && this.getEventPrecursorChartData();
            })
          } else {
            this.setState({
              eventPreCursorTableData: [],
              eventPreCursorChartData: [],
              totalDevicesCount: 0,
              epTableSelectedRow: {},
              tableLoader: false,
              chartLoader:false
            })
          }
        }).catch(err => {
          console.log(err)
        });
    })
  }
  getEventPrecursorChartData = () => {
    eventPrecursorChartData({
      'apiType': "data",
      'hierid': this.state?.epTableSelectedRow?.hierid || null,
      'feederid': this.state?.epTableSelectedRow?.feederid || null,
      'siteid': this.state?.epTableSelectedRow?.siteid || null,
      "ENDTIMESTAMP": encodeURIComponent(moment(moment(this.state.chartEndTimestamp)).utc().format('YYYY-MM-DD HH:mm:ss')),
    })
      .then(data => {
        if (data) {
          this.formatEventPrecursorChartData(data);
        } else this.setState({ chartLoader: false })
      }).catch(err => {
        this.setState({ chartLoader: false })
      });

  }
  formatEventPrecursorChartData = (pData) => {
    if (pData.filter(e => e.name === "Status").length > 0) {
      let status = ''
      pData.filter(e => e.name === "Status")[0].lineWidth = 0;
      pData.filter(e => e.name === "Status")[0].showInLegend = false;
      pData.filter(e => e.name === "Status")[0].data.length > 0 && pData.filter(e => e.name === "Status")[0].data.forEach(e1 => {
        if (e1.status !== status) {
          e1.y = 99;
          e1.marker = e1.status === "Warning" ? {
            symbol: `url(${require('../../../../resources/images/warning-yellow.svg.png')})`,
            width: 16,
            height: 16,
          } : e1.status === "Alert" ? {
            symbol: `url(${require('../../../../resources/images/warning-red.svg.png')})`,
            width: 16,
            height: 16,
          } : e1.status === "Watch" ? {
            symbol: `url(${require('../../../../resources/images/warning-blue.svg.png')})`,
            width: 16,
            height: 16,
          } : {
            symbol: `url(${require('../../../../resources/images/warning-green-new-svg.png')})`,
            width: 13,
            height: 13,
          };
          status = e1.status;
        }
      });
    }
    let yAxisObj = { "disturbance": 0, "weather": 2, "precipitation": 1 };
    let chartObj = {
      "Anomalies": {
        "color": "#A28B55",
        "type": "column"
      },
      "Temperature": {
        "color": "#FFA823",
        "type": "spline"
      },
      "Precipitation": {
        "color": "#3DC2EC",
        "type": "spline"
      },
      "Operational": {
        "color": "#C8ACD6",
        "type": "column",
        "visible": false
      },
      "Max Wind Gust": {
        "color": "#2F3645",
        "type": "spline"
      },
      "Bad Data": {
        "color": "#7FA1C3",
        "type": "column",
        "visible": false
      },
      "Detrimental": {
        "color": "#694F8E",
        "type": "column",
        "visible": false
      },
      "Status": {
        "type": "scatter"
      }
    }
    let finalData = pData.map(e => {
      e.name = e.name.split("(")[0].trim();
      return {
        ...e, "yAxis": e.name === "Status" ? 3 : yAxisObj[e.partOf], "color": chartObj[e.name]?.color, visible: chartObj[e.name].visible, "type": chartObj[e.name]?.type, "zIndex": ["spline", "scatter"].includes(chartObj[e.name]?.type) ? 1 : 0

      }
    });
    finalData.forEach(e => {
      e.data.forEach(e1 => e1.x = new Date(moment.utc(e1.x).local().format('MM/DD/YYYY HH:mm:ss')))
    })
    this.setState({
      ...this.state,
      eventPreCursorChartData: finalData,
      chartLoader: false
    })
  }
  handleClickFilterReset = () => {
    this.setState({
      escalationTypeDD: {
        ...this.state.escalationTypeDD,
        selected: [{ name: "Alert", text: "Alert", value: "Alert", keyIndex: 0 }, { name: "Warning", text: "Warning", value: "Warning", keyIndex: 1 }, { name: "Watch", text: "Watch", value: "Watch", keyIndex: 2 }]
      },
      totalBadgeCount: 1,
      disableFilterReset : true
    }, () => {
      eventPrecursorData.tableOptions.pageNo = 1;
      this.getEventPrecursorTableData();
    })
  }
  handleClickFilterApply = () => {
    this.setState({
      totalBadgeCount: this.state.escalationTypeDD.selected.length > 0 ? 1 : 0,
      disableFilterReset : JSON.stringify([{ name: "Alert", text: "Alert", value: "Alert", keyIndex: 0 }, { name: "Warning", text: "Warning", value: "Warning", keyIndex: 1 }, { name: "Watch", text: "Watch", value: "Watch", keyIndex: 2 }].sort((a, b) => a.name.localeCompare(b.name))) === JSON.stringify(this.state.escalationTypeDD.selected.sort((a, b) => a.name.localeCompare(b.name)))
    }, () => {
      eventPrecursorData.tableOptions.pageNo = 1;
      this.getEventPrecursorTableData();
    });
  }
  checkDST=(timestamp) =>{
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const standardTimeDate = new Date(year, 0, 1);
    const standardOffset = standardTimeDate.getTimezoneOffset();
    const currentOffset = date.getTimezoneOffset();
    const isDST = currentOffset < standardOffset;
    return isDST
} 
  prevNextMonthSelection = (pVal) => {
    let chartStartTimestamp, chartEndTimestamp;
    if (pVal === "prev") {
      chartStartTimestamp = this.checkDST((this.state.chartStartTimestamp -  (30 * 1000 * 60 * 60 * 24))) ? new Date((this.state.chartStartTimestamp -  (30 * 1000 * 60 * 60 * 24))-(60 * 60 * 1000)).setHours(0, 0, 0, 0) : new Date((this.state.chartStartTimestamp -  (30 * 1000 * 60 * 60 * 24))).setHours(0, 0, 0, 0);
      chartEndTimestamp = this.checkDST((this.state.chartEndTimestamp -  (30 * 1000 * 60 * 60 * 24))) ? new Date((this.state.chartEndTimestamp -  (30 * 1000 * 60 * 60 * 24))-(60 * 60 * 1000)).setHours(23, 59, 59, 999) : new Date((this.state.chartEndTimestamp -  (30 * 1000 * 60 * 60 * 24))).setHours(23, 59, 59, 999);
    }
    else {
      chartStartTimestamp = this.checkDST((this.state.chartStartTimestamp +  (30 * 1000 * 60 * 60 * 24))) ? new Date((this.state.chartStartTimestamp +  (30 * 1000 * 60 * 60 * 24))-(60 * 60 * 1000)).setHours(0, 0, 0, 0) : new Date((this.state.chartStartTimestamp +  (30 * 1000 * 60 * 60 * 24))).setHours(0, 0, 0, 0);
      chartEndTimestamp = this.checkDST((this.state.chartEndTimestamp +  (30 * 1000 * 60 * 60 * 24))) ? new Date((this.state.chartEndTimestamp +  (30 * 1000 * 60 * 60 * 24))-(60 * 60 * 1000)).setHours(23, 59, 59, 999) : new Date((this.state.chartEndTimestamp +  (30 * 1000 * 60 * 60 * 24))).setHours(23, 59, 59, 999);
    }
    this.setState({
      chartStartTimestamp: chartStartTimestamp,
      chartEndTimestamp: chartEndTimestamp,
      chartLoader: true
    }, () => {
      setTimeout(() => {
        this.getEventPrecursorChartData();
      }, 1000)
    });
  }
  updateEventPrecursorUtilityValues = (pageNo, sizePerPage) => {
    if (pageNo !== undefined) {
      eventPrecursorData.tableOptions.pageNo = pageNo + 1;
    }
    if (sizePerPage !== undefined) {
      eventPrecursorData.tableOptions.pageSize = sizePerPage;
      eventPrecursorData.tableOptions.pageNo = 1;
    }
    eventPrecursorData.tableOptions.selectedRows = [];
    this.setState({ tableLoader: true }, () => {
      this.getEventPrecursorTableData();
    })
  }
  setMessage = (status, type, msg) => {
    this.setState({
      message: {
        msg: msg,
        status: status,
        type: type
      }
    });
  }
  tableSortChange = () => {
    this.getEventPrecursorTableData();
  }
  loadData = (nodeData, nodeValue, type) => {
    if (nodeValue && type !== 'ROOTNODE') {
      const index = this.state.treeNode.routeParams && this.state.treeNode.routeParams.findIndex(item => item === type);
      let obj = this.state.treeNode.routeParams ? index !== -1 ? this.state.treeNode.routeParams[index - 1].children.find(item => item.name === nodeValue) :
        this.state.treeNode.children.find(item => item.name === nodeValue) :
        this.state.treeNode.children.find(item => item.type === type && item.name === nodeValue);
      obj = closeUnusedTreeNodes(obj, type);
      obj && this.props.loadNextLevel({ "node": obj }, obj.routeParams, null, obj.name);
    }
  }
  downloadData = (pType, treeNode = this.state.treeNode) => {
    const params = {
      "hierid": treeNode.type !== "ROOTNODE" ? treeNode.type === "REGION" ? treeNode.routeParams[3]?.id : treeNode.routeParams[5]?.id : null,
      "feederid": treeNode.type === "FEEDER" ? treeNode.routeParams[7]?.id : null,
      "ESCALATIONTYPE": this.state.escalationTypeDD.selected.length > 0 ? this.state.escalationTypeDD.selected.map(e => e.name).toString() : this.state.escalationTypeDD.options.map(e => e.name).toString(),
      "ENDTIMESTAMP": encodeURIComponent(moment(moment(this.state.endTimestamp)).utc().format('YYYY-MM-DD HH:mm:ss:SSS')),
      "ORDERING": eventPrecursorData.tableOptions.ordering.length > 0 ? eventPrecursorData.tableOptions.ordering.map(e => e.desc ? `-${e.id}` : `${e.id}`).toString() : null,
      'apiType': "data",
      'timeZone':encodeURIComponent(this.state.roleAndPermission.timezone)
    };
    downloadFile(params)
      .then(data => {
        if (typeof(data) == "object") {
          setDownloadCSV(data)
        }else{
          this.setMessage(true, "fail", data);
        }
      }).catch(err => {
        console.log(err)
      });
  }
  render() {
    return (
      <EventPrecursorContext.Provider value={{
        state: this.state,
        onCalenderCallback: this.calenderCallback,
        handleRowOnClick: this.handleRowOnClick,
        onValChange: this.onValChange,
        onUpdateActionValue: this.updateActionValue,
        onCloseModal: this.closeModal,
        onHandleClickFilterApply: this.handleClickFilterApply,
        onHandleClickFilterReset: this.handleClickFilterReset,
        onPrevNextMonthSelection: this.prevNextMonthSelection,
        updateEventPrecursorUtilityValues: this.updateEventPrecursorUtilityValues,
        setMessage: this.setMessage,
        onCloseAlertMessage: this.closeAlertMessage,
        onTableSortChange: this.tableSortChange,
        loadData: this.loadData,
        downloadData: this.downloadData
      }}>
        {this.props.children}
      </EventPrecursorContext.Provider>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    roleAndPermission: state.loginData.roleAndPermission
  }
}

export default withRouter(connect(mapStateToProps, {})(EventPrecursorProvider));

export const MonitorConsumer = EventPrecursorContext.Consumer;