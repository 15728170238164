import React, { Component } from 'react'
import { LicenceContext } from '../provider/licenceProvider'
import { FormattedMessage } from 'react-intl';
import { Col, Row, Accordion, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { isGreaterThanTodaysDate } from '../../../filters/dateHelper';

class LicenseAccordion extends Component {
    static contextType = LicenceContext;

    getExpiresOnDetails = (data, section) => {
        if (data === 'Available to License')
            return section ? 'Available to License' : <FontAwesomeIcon className={'colorBlue'} icon={faMinusCircle} size="sm" />
        else if (data === 'Not Visible')
            return section ? 'Not Visible' : <FontAwesomeIcon className={'colorRed'} icon={faMinusCircle} size="sm" />
        else if (isGreaterThanTodaysDate(data))
            return section ? data : <FontAwesomeIcon className={'colorGreen'} icon={faCheckCircle} size="sm" />
        else
            return section ? data + ' (Available to License)' : <FontAwesomeIcon className={'colorBlue'} icon={faMinusCircle} size="sm" />
    }

    render() {
        return (
            <React.Fragment>
                {Object.values(this.context.state.licenceData).every(item => item.visible === false) ? <div className='text-align-center m-5'><FormattedMessage id={`db.licMngmnt.emptyMsg`} /></div> :
                    <Accordion className='mt-3'>
                        <Card>
                            <Accordion as={Card.Header}>
                                <Row>
                                    <Col><b><FormattedMessage id={`db.licMngmnt.license`} /></b></Col>
                                    <Col><b><FormattedMessage id={`db.licMngmnt.expiresOn`} /></b></Col>
                                    <Col><b><FormattedMessage id={`db.licMngmnt.visibility`} /></b></Col>
                                </Row>
                            </Accordion>
                        </Card>
                        {Object.keys(this.context.state.licenceData).length > 0 && Object.keys(this.context.state.licenceData).map((parent, key) => {
                            if (this.context.state.licenceData[parent].visible) {
                                return parent === 'manage' || parent === 'events' || parent === 'insights' ?
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey={key} className='lic-accord'>
                                            <Row>
                                                <Col><FormattedMessage id={`nav.main.${parent}`} /></Col>
                                                <Col className='pl-5'><span>{this.getExpiresOnDetails(this.context.state.licenceData[parent].expiresOn, 'date')} </span></Col>
                                                <Col className='pl-5'>{this.getExpiresOnDetails(this.context.state.licenceData[parent].expiresOn)}</Col>
                                            </Row>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={key}>
                                            <Card.Body>
                                                {
                                                    this.context.state.licenceData[parent].childNodes.map(child => {
                                                        if (child.visible) {
                                                            return <Row>
                                                                <Col><span>{child.name}</span></Col>
                                                                <Col className='pl-5'><span>{this.getExpiresOnDetails(child.expiresOn, 'date')} </span></Col>
                                                                <Col className='pl-5'>{this.getExpiresOnDetails(child.expiresOn)}</Col>
                                                            </Row>
                                                        }
                                                    })
                                                }
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    :
                                    <Card>
                                        <Accordion as={Card.Header}>
                                            <Row>
                                                <Col><FormattedMessage id={`nav.main.${parent}`} /></Col>
                                                <Col className='pl-5'><span>{this.getExpiresOnDetails(this.context.state.licenceData[parent].expiresOn,'date')} </span></Col>
                                                <Col className='pl-5'><span>{this.getExpiresOnDetails(this.context.state.licenceData[parent].expiresOn)} </span></Col>
                                            </Row>
                                        </Accordion>
                                    </Card>
                            }
                        })}
                    </Accordion>
                }
            </React.Fragment>
        );
    }
}

export default LicenseAccordion;
