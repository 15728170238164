import React, { createContext } from 'react';
import { tableOptions } from '../../../utility/customReportsUtility';
import { getCustomReports, getSitesByParents, exportData, scheduleReport } from '../services/customReportService';
import { getUsersList } from '../../userManagement/services/userService';
import moment from 'moment-timezone';
import { saveAs } from 'file-saver';
import { convertReportFrequency,reportFrequency } from '../../../utility/customReportsUtility';
import TableMultiSortNumber from '../../../hoc/tableMultiSortNumber'
import { dateConvertion } from '../../../services/utilityService';
import { numWithOptDeciRgx } from '../../../filters/commonFunction';
import { FormattedMessage } from 'react-intl';
import { getDateConvertedString } from '../../../services/utilityService';
import { connect } from 'react-redux';

export const CustomReportContext = createContext();


class CustomReportProvider extends React.Component {

    state = {
        loader: false,
        selectedReportObj: this.props.selectedReportObj,
        displayStartDt: "",
        displayEndDt: "",
        tableHeader: [],
        tableData: [],
        prevReportId: "",
        pageNo: tableOptions.pageNo,
        pageSize: tableOptions.pageSize,
        // pageNo : 1,
        // pageSize : 5,
        pageSizeOptions: tableOptions.pageSizeOptions,
        tableColumnFilter: {
            totalItems: [],
            selectedItems: []
        },
        data: {},
        filters: [],
        treeViewCheckedData: [],
        displayNameErr: false,
        displayUsersErr: false,
        displayEndDateErr: false,
        filterPercentErr: "",
        usersList: [],
        scheduleFilterData: {
            frequency: reportFrequency[0],
            startDate: moment(new Date()).format('MM/DD/YYYY'),
            endDate: "",
            emptyReports: true,
            name: "",
            users: []
        },
        scheduleFilterUserList:[],
        scheduleFilterLoader:false
    }

    updateSelectedReportDetails = (pval) => {
        this.setState({
            selectedReportObj: pval
        })
    }

    updateTableData = (pData) => {
        this.setState({
            tableHeader: pData.header,
            tableData: pData.body,
            displayStartDt: pData.startDate,
            displayEndDt: pData.endDate,
            loader: false,
            tableColumnFilter: pData.tableColumnFilter,
            data: pData.data
        })
    }

    getReportData = (requestData) => {
        this.setState({ loader: true }, () => {
            getCustomReports(this.state.selectedReportObj.id, this.state.pageNo, this.state.pageSize, requestData).then((data) => {
                if (data && data.status !== "FAIL") {
                const header = data.headers ? data.headers.map(e => ({ Header: (original) => <TableMultiSortNumber column={original} label={e} />, accessor: e })) : [];
                const totalItems = data.headers ? data.headers.map(e => ({ label: e, value: e })) : [];
                const arr1 = [];
                for (let key in data.data) {
                    const obj = {}
                    for (let key1 in data.headers) {
                        // obj[data.headers[key1]] = data.data[key][key1];
                        obj[data.headers[key1]] = data.headers[key1].includes("%") ? Math.round(data.data[key][Number(data.columnDescriptions[key1].columnIndex) - 1]) : data.data[key][Number(data.columnDescriptions[key1].columnIndex) - 1]
                    }
                    if(obj.Date) obj.Date = getDateConvertedString(obj.Date)
                    if(obj['Last Communication Time']) obj['Last Communication Time'] = getDateConvertedString(obj['Last Communication Time']) // for RMA Issues reports
                    arr1.push(obj)
                }
                const body = arr1;
                if (data.filters && data.filters.length) this.prepareFilter(data.filters) 
                else this.setState({filters: []});
                this.updateTableData({
                    header: header,
                    body: body,
                    startDate:data.startDate ? moment(data.startDate.placeholderValue).format("MM/DD/YYYY") : null,
                    endDate: data.endDate ? moment(data.endDate.placeholderValue).format("MM/DD/YYYY") : null,
                    tableColumnFilter: {
                        totalItems: totalItems,
                        selectedItems: data.headers ? data.columnDescriptions && data.columnDescriptions.map(e => e.displayable && e.columnAlias).filter(e => typeof(e) === "string") : []
                    },
                    data: data
                })
            }else{
                this.setState({
                    tableData: [],
                    loader: false,
                })
            }
            })
        });
    }

    prepareFilter = (filtersList) => {
        let tArr = [];
        for (let keys in filtersList) {
            let filterObj = {
                name: filtersList[keys].placeholder,
                label: filtersList[keys].name,
                value: "",
                additionalInfo: filtersList[keys],
                type: (filtersList[keys].type === "List") ? "Select" : filtersList[keys].type
            };
            if ((filtersList[keys].type === "Text") || (filtersList[keys].type === "Range") || (filtersList[keys].type === "Boolean")) {
                filterObj.value = filtersList[keys].placeholderValue;
            } else if (filtersList[keys].type !== "Hierarchy") {
                if (filtersList[keys].hierarchyDetails && filtersList[keys].hierarchyDetails.multiSelect === false) {
                    let items = filtersList[1].results.map(e => { return ({ text: e[1], value: e[0] }) });
                    filterObj.selection = { list: items, selected: filtersList[keys].results.find(e => e[0] === parseInt(filtersList[keys].placeholderValue))[1], placeholderValue: filtersList[keys].placeholderValue };
                } else {

                }
            }
            tArr.push(filterObj);
        }
        tArr = tArr.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
        this.setState({
            filters: tArr
        });
    }

    onPageSizeOptionChange = (pageSize) => {
        this.setState({ pageSize: pageSize, pageNo: 1 }, () => {
            this.getTableData();
        });
    }
    onPageChange = (pageNo,pageSize)=>{
        this.setState({ pageNo: pageNo }, () => {
            this.getTableData();
        });
    }
    applyfilter = () =>{
        this.setState({ pageNo: 1 }, () => {
            this.getTableData();
        });
    }
    getTableData = async () => {
        const params = await this.formReqTableParam();
        this.getReportData(params);
    }

    getSelectedColumns = () => {
        this.state.data.columnDescriptions && this.state.data.columnDescriptions.forEach(e => e.displayable = this.state.tableColumnFilter.selectedItems.includes(e.columnAlias));
        return this.state.data.columnDescriptions ? this.state.data.columnDescriptions : null;
    };

    getFilterValues = async () => {
        let filterValsStr = '';
        if (this.state.allCheckedData) {
            let eVal = this.state.filters.find(e => e.type === "Hierarchy");
            //let hierarchyData = this.getSelectedTreeData(this.state.allCheckedData);
            let hierarchyData = this.state.allCheckedData;
            if (hierarchyData.feederIds.length && Number(eVal.additionalInfo.hierarchyDetails.maxHierarchyLevel) === 3) filterValsStr += (filterValsStr ? ',' : '') + hierarchyData.feederIds.join(',');
            if (hierarchyData.lateralIds.length && Number(eVal.additionalInfo.hierarchyDetails.maxHierarchyLevel) === 4) filterValsStr += (filterValsStr ? ',' : '') + hierarchyData.lateralIds.join(',');
            if (Number(eVal.additionalInfo.hierarchyDetails.maxHierarchyLevel) === 5) {
                if (hierarchyData.siteIds.length) filterValsStr += (filterValsStr ? ',' : '') + hierarchyData.siteIds.join(',');
                if (hierarchyData.lateralSiteIds.length) filterValsStr += (filterValsStr ? ',' : '') + hierarchyData.lateralSiteIds.join(',');
            }
            let parentsObj = {};
            if (hierarchyData.regionNames.length) parentsObj.regionNames = hierarchyData.regionNames;
            if (hierarchyData.substationHierIds.length) parentsObj.substationHierIds = hierarchyData.substationHierIds;
            if (hierarchyData.feederIds.length && Number(eVal.additionalInfo.hierarchyDetails.maxHierarchyLevel) > 3) parentsObj.feederIds = hierarchyData.feederIds;
            if (hierarchyData.lateralIds.length && Number(eVal.additionalInfo.hierarchyDetails.maxHierarchyLevel) > 4) parentsObj.lateralIds = hierarchyData.lateralIds;
            if (parentsObj.regionNames || parentsObj.substationHierIds || parentsObj.feederIds || parentsObj.lateralIds) {
                const data = await getSitesByParents(parentsObj, this.props.tree[0].id);
                if (data && data.length > 0) {
                    let feederIds = [], lateralIds = [], siteIds = [], lateralSiteIds = [];
                    for (let i = 0; i < data.length; i++) {
                        let dataItem = data[i];
                        if (feederIds.indexOf(dataItem.feederId) === -1) feederIds.push(dataItem.feederId);
                        if (dataItem.lateralId) {
                            if (lateralIds.indexOf(dataItem.lateralId) === -1) lateralIds.push(dataItem.lateralId);
                            if (lateralSiteIds.indexOf(dataItem.id) === -1) lateralSiteIds.push(dataItem.id);
                        } else {
                            if (siteIds.indexOf(dataItem.id) === -1) siteIds.push(dataItem.id);
                        }
                    }
                    if (feederIds.length && Number(eVal.additionalInfo.hierarchyDetails.maxHierarchyLevel) === 3) filterValsStr += (filterValsStr ? ',' : '') + feederIds.join(',');
                    if (lateralIds.length && Number(eVal.additionalInfo.hierarchyDetails.maxHierarchyLevel) === 4) filterValsStr += (filterValsStr ? ',' : '') + lateralIds.join(',');
                    if (Number(eVal.additionalInfo.hierarchyDetails.maxHierarchyLevel) === 5) {
                        if (siteIds.length) filterValsStr += (filterValsStr ? ',' : '') + siteIds.join(',');
                        if (lateralSiteIds.length) filterValsStr += (filterValsStr ? ',' : '') + lateralSiteIds.join(',');
                    }
                }

            }
        }

        return this.state.filters.map(e => {
            if (e.type === "Select") {
                return ({
                    name: e.label,
                    type: e.type === "Select" ? "List" : e.type,
                    placeholder: e.name,
                    placeholderValue: e.selection.placeholderValue
                })
            } else if (e.type === "Hierarchy") {
                if (this.state.allCheckedData) {
                    return ({
                        name: e.label,
                        type: e.type,
                        placeholder: e.name,
                        placeholderValue: filterValsStr
                    })
                } else {
                    return ({
                        name: e.label,
                        type: e.type,
                        placeholder: e.name,
                        placeholderValue: e.additionalInfo.results.join()
                    })
                }
            }else if(e.type === "Text"){
                return ({
                    name: e.label,
                    type: e.type,
                    placeholder: e.name,
                    placeholderValue: e.value
                })
            }
        })
    }

    formReqTableParam = async () => {
        let filterVal = await this.getFilterValues();
        const requestDataObj = {
            "startDate": {
                "placeholder": "startDate",
                "placeholderValue": moment(this.state.displayStartDt).format("YYYY-MM-DDTHH:mm:ss")
            },
            "endDate": {
                "placeholder": "endDate",
                "placeholderValue": moment(this.state.displayEndDt).format("YYYY-MM-DDTHH:mm:ss")
            },
            columnDescriptionList: this.getSelectedColumns(),
            filters: filterVal
        };
        return {
            ...requestDataObj
        }
    }

    setColumnStatus = (value) => {
        let prevData = this.state.tableColumnFilter.selectedItems;
        let filterData = [];
        if (prevData.includes(value)) filterData = prevData.filter(item => item !== value)
        else filterData = [...prevData, value]
        let tableColumnFilter = { ...this.state.tableColumnFilter }
        tableColumnFilter.selectedItems = filterData;
        this.setState({ tableColumnFilter: tableColumnFilter })
    }

    calenderCallback = (start, end) => {
        this.setState({
            displayStartDt: moment(new Date(start)).format('MM/DD/YYYY'),
            displayEndDt: moment(new Date(end)).format('MM/DD/YYYY'),
        });
    }

    updateSingleSelectVal = (e, item) => {
        let tempFilters = JSON.parse(JSON.stringify(this.state.filters));
        tempFilters.forEach(itm => {
            if (itm.name === item && itm.selection) {
                itm.selection.placeholderValue = e.value;
                itm.selection.selected = e.text
            }
        })
        this.setState({
            filters: tempFilters
        });    
    }

    receiveUpdatedData = (allData) => this.setState({ allCheckedData: allData })

    getSelectedTreeData = (subscribedNode) => {
        let treeData = {
            regionNames: [],
            substationHierIds: [],
            feederIds: [],
            lateralIds: [],
            siteIds: [],
            lateralSiteIds: [],
            selectedObj: [],
        };
        subscribedNode.forEach(function (obj) {
            if (obj.regionSelected) {
                treeData.selectedObj.push(obj.regionName);
                let children = obj.hasOwnProperty("substations") ? obj.substations : [];
                if (children.length > 0) {
                    children.forEach(function (subobj) {
                        if (subobj.substationSelected) {
                            let feederChildren = subobj.hasOwnProperty("feeders") ? subobj.feeders : [];
                            if (feederChildren.length > 0) {
                                feederChildren.forEach(function (feederobj) {
                                    if (feederobj.feederSelected) {
                                        treeData.feederIds.push(feederobj.id);
                                    }
                                });
                            } else {
                                treeData.substationHierIds.push(subobj.id);
                            }
                        }
                    });
                } else {
                    treeData.regionNames.push(obj.regionName);
                }
            }
        });
        return treeData;
    }

    getExportData = (type) => {
        this.setState({ loader: true }, async () => {
            const requestParams = await this.formReqTableParam();
            requestParams.responseType = type;
            requestParams.columnDescriptionList = this.getSelectedColumns();
            const fileExt = requestParams.responseType === 'excel' ? '.xls' : '.csv';
            exportData(this.state.selectedReportObj.id, requestParams)
                .then((data) => {
                    this.setState({ loader: false });
                    const fileName = this.state.selectedReportObj.name.replace(/\s/g,"_") + '_' + moment(new Date()).format("MM_DD_YYYY") + fileExt;
                    const fileData = new Blob([data], { type: "application/octet-stream" });
                    saveAs(fileData, fileName)
                }).catch((err) => this.setState({ loader: false }))
        })
    }

    updateScheduleFilterFormData = (fieldName, value,picker,endDateRef) => {
        if (typeof value === "object") {
            if (fieldName === "emptyReports") value = value.target.checked;
            if (fieldName === "name") value = value.target.value;
            if (fieldName === "startDate"){
                endDateRef.$picker.data("daterangepicker").minDate = moment(dateConvertion(value, 'startDate', this.state.timezone));
                value = value.format('MM/DD/YYYY');
            } 
            else if(fieldName === "endDate") value = value.format('MM/DD/YYYY');
        }
        this.setState({
            scheduleFilterData: {
                ...this.state.scheduleFilterData,
                [fieldName]: value
            }
        });
    }

    onScheduleBtnClickHandler = async (params1,params2,params3) => {
        const bool = await this.inputValid();
        const validEmails = this.state.scheduleFilterData.users.length > 0 && this.state.scheduleFilterData.users.map(x => x.value);
        if (!bool) {
            this.setState({
                scheduleFilterLoader:true
            });
            let reportReq = {};
            reportReq.name = this.state.scheduleFilterData.name + "_" + new Date().getTime();
            reportReq.frequency = convertReportFrequency[this.state.scheduleFilterData.frequency.text];
            reportReq.dontSendEmptyReports = this.state.scheduleFilterData.emptyReports;
            reportReq.emailIds = validEmails.toString();
            reportReq.schedulerStartDate = moment(this.state.scheduleFilterData.startDate).startOf('day').format("YYYY-MM-DDTHH:mm:ss");
            if (this.state.scheduleFilterData.endDate) reportReq.schedulerEndDate = moment(this.state.scheduleFilterData.endDate).endOf('day').format("YYYY-MM-DDTHH:mm:ss");
            const data = await this.formReqTableParam();
            await scheduleReport(this.state.selectedReportObj.id, { ...reportReq, ...data })
                .then(res => {
                    if (res.status === "OK") {
                        this.resetSchedular(params2,params3);
                    } 
                    else {
                        this.resetSchedular(params2,params3);
                    }
                    this.setState({
                        scheduleFilterLoader:false
                    },()=>{ params1.current.click();});
                }).catch(err => {
                    this.resetSchedular(params2,params3);
                    this.setState({
                        scheduleFilterLoader:false
                    },()=>{ params1.current.click();});
                 })
        }
    }

    inputValid = () => {
        let isInputValid = false;
        if ((this.state.scheduleFilterData.name).trim() === "" || this.state.scheduleFilterData.name.includes('/')) {
            this.setState({ displayNameErr: true });
            isInputValid = true;
        } else this.setState({ displayNameErr: false });
        if (this.state.scheduleFilterData.users.length === 0) {
            this.setState({ displayUsersErr: true });
            isInputValid = true;
        } else this.setState({ displayUsersErr: false });
        if (this.state.scheduleFilterData.endDate === "") {
            this.setState({ displayEndDateErr: true });
            isInputValid = true;
        } else this.setState({ displayEndDateErr: false });
        
        return isInputValid;
    }

    resetSchedular = (multiselectRef,endDateRef) => {
        let userList = [...this.state.scheduleFilterUserList];
        userList.forEach(e => e.name = e.name.split("<")[0].trim());   
        this.setState({
            scheduleFilterData: {
                ...this.state.scheduleFilterData,
                frequency: reportFrequency[0],
                startDate: moment(new Date()).format('MM/DD/YYYY'),
                endDate: "",
                emptyReports: true,
                name: "",
                users: []
            },
            scheduleFilterUserList:userList
        },()=>{
            multiselectRef.resetSelectedValues();   
            endDateRef.setEndDate("");
        })
    }

    onSelectHandler = (selectedList,selectedItem,action) => {
        selectedList.forEach(e => e.name = e.displayName);
        if(action === "remove"){
            selectedItem.name = selectedItem.name.split("<")[0].trim();
        }
    
        this.setState({
            scheduleFilterData: {
                ...this.state.scheduleFilterData,
                users: selectedList
            }
        })
    }

    updateTextFieldVal = (e, item) => {
        let tempFilters = JSON.parse(JSON.stringify(this.state.filters));
        tempFilters.find(el => el.name == item).value = e.target.value;
        let numOnlyRegex = numWithOptDeciRgx;
        if (item === "percentVal" && (!numOnlyRegex.test(e.target.value) || !e.target.value || e.target.value < 0 || e.target.value > 100 || !(e.target.value && e.target.value.trim()))) {
            this.setState({ filters: tempFilters, filterPercentErr: <FormattedMessage id={`customreports.percentVal.vaidation`} /> });
        } else {
            this.setState({ filters: tempFilters, filterPercentErr: "" });
        }
    }

    async componentDidMount() {
        const data = await getUsersList();
        if (data && data.length > 0) {
            let schedulerUserList = data.map(option => ({
                "name": option.userName,
                "value": option.email,
                "displayName":`${option.userName} <${option.email}>`
            }))
            this.setState({ usersList: data, scheduleFilterUserList:schedulerUserList});
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedReportObj.id !== prevProps.selectedReportObj.id && this.props.selectedReportObj.name !== prevProps.selectedReportObj.name) {
            this.setState({
                selectedReportObj: this.props.selectedReportObj,
                pageNo:1,
                pageSize:25,
                allCheckedData:null
            })
        }
    }

    render() {
        return (
            <CustomReportContext.Provider value={{
                state: this.state,
                updateSelectedReportDetails: this.updateSelectedReportDetails,
                getReportData: this.getReportData,
                onPageSizeOptionChange: this.onPageSizeOptionChange,
                setColumnStatus: this.setColumnStatus,
                calenderCallback: this.calenderCallback,
                getTableData: this.getTableData,
                updateSingleSelectVal: this.updateSingleSelectVal,
                receiveUpdatedData: this.receiveUpdatedData,
                onExportClick: this.getExportData,
                updateScheduleFilterFormData: this.updateScheduleFilterFormData,
                onScheduleBtnClickHandler: this.onScheduleBtnClickHandler,
                onSelectHandler: (selectedList,selectedItem)=>{this.onSelectHandler(selectedList,selectedItem,"add")},
                onRemoveHandler: (selectedList,selectedItem)=>{this.onSelectHandler(selectedList,selectedItem,"remove")},
                onPageChange:this.onPageChange,
                applyfilter: this.applyfilter,
                resetSchedular:this.resetSchedular,
                updateTextFieldVal:this.updateTextFieldVal
            }}>
                {this.props.children}
            </CustomReportContext.Provider>
        )
    }

}
const mapStateToProps = (state) => {
    return {
        tree: state.treeviewData.tree
    }
}
export default connect(mapStateToProps, {})(CustomReportProvider);