import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import ModalWindow from '../../../../hoc/modelWindow';
import { EventPrecursorContext } from '../provider/eventPrecursorProvider';
import { eventPrecursorData } from '../../../../utility/eventPrecursorUtility';
import Table from '../../../../hoc/table';
import { viewEventPrecursor } from '../services/eventPrecursorService';
import Loader from '../../../../hoc/loader';
import NoDataAvailable from '../../../../hoc/noDataAvailableComponent';
import { FormattedMessage } from 'react-intl';
class ViewEventPrecursorModal extends Component {
  static contextType = EventPrecursorContext;
  state = {
    modalTitle: <span><FormattedMessage id={`events.eventPrecursor.impendingOutages`} /> : <span className='font-weight-normal small'>{`${this.context.state.epTableSelectedRow.region} - ${this.context.state.epTableSelectedRow.substation} - ${this.context.state.epTableSelectedRow.feeder} - ${this.context.state.epTableSelectedRow.feedersite}`}</span></span>,
    viewEventPrecursorTableColumn: [
      ...eventPrecursorData.viewEventPrecursorTableColumn,
    ],
    tableLoader: false,
    viewEventPrecursorTableData: []
  }
  componentDidMount() {
    this.setState({
      tableLoader: true
    }, () => {
      this.getTableData();
    });
  }
  getTableData = () => {
    viewEventPrecursor({
      'apiType': "data",
      'hierid': this.context.state.epTableSelectedRow.hierid,
      'feederid': this.context.state.epTableSelectedRow.feederid,
      'siteid': this.context.state.epTableSelectedRow.siteid,
      'PAGENO': eventPrecursorData.viewEventPrecursorTableOptions.pageNo,
      'PAGESIZE': eventPrecursorData.viewEventPrecursorTableOptions.pageSize
    })
      .then(data => {
        if (data) {
          this.setState({
            viewEventPrecursorTableData: data.length > 0 ? data : [],
            tableLoader: false
          })
        } else {
          this.setState({
            viewEventPrecursorTableData: [],
            tableLoader: false
          })
        }
      }).catch(err => {
        console.log(err)
      });
  }
  render() {
    let modalBody = <React.Fragment>
      {
        this.state.tableLoader ? <Loader elementClass="tree-ajax-loader center-element" /> :
          <Row className={`event-precursor-table`}>
            <Col className='m-1 p-1'>
              {
                this.state.viewEventPrecursorTableData.length > 0 ?
                  <Table
                    tableColumn={this.state.viewEventPrecursorTableColumn}
                    tableRowData={this.state.viewEventPrecursorTableData}
                    isDisplayPageRows={false}
                    pageSize={this.state.viewEventPrecursorTableData.length}
                    sortable={false}
                  /> :
                  <NoDataAvailable />
              }

            </Col>
          </Row>
      }
    </React.Fragment>

    return (
      <Row>
        <ModalWindow
          show={this.context.state.showModal}
          size="lg"
          dialogClassName={"event-precursor-modal modal-dialog-centered"}
          title={this.state.modalTitle}
          onBtnClickHandler={this.onBtnClickHandler}
          onHide={() => this.context.onCloseModal()}
          modeldata={{
            content: modalBody
          }}
          footer={
            []
          }
        />
      </Row>
    )
  }
}
export default ViewEventPrecursorModal;