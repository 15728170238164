import React, { Component} from 'react';
import { Col } from 'react-bootstrap';
import GoogleMapReact from 'google-map-react';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../../hoc/loader';
import { MapContext } from '../provider/mapProvider';
import StreetViewModal from '../components/streetViewModal';
import ModalWindow from '../../../hoc/modelWindow';

class MapContainer extends Component {
    static contextType = MapContext;
    
    render() {
        const { breadCrumb, mapZoom, center, isOpenStreetViewModal, streetViewLabel, isLodingStreet, streetViewAvailable,isNodeviceLocationFound } = this.context.state;
        let LoaderData = (this.context.state.loading) ? <Loader elementClass="tree-ajax-loader center-element" /> : null
        return (
        <React.Fragment>
            {LoaderData}
            <Col className="content-right pad-rgt-lef m-0 p-0" xs={12} md={12}>
                <div className="map-view-header">
					<div className="map-title"> 
						<span onClick={() => this.context.getMapData()} tooltip-placement="left" tooltip="Click to refresh map data" className="ion ion-refresh map-refresh btn">
                            <FontAwesomeIcon icon={faRedo} size="sm" />
                        </span> &nbsp;
                        <FormattedMessage id={`mapview.title`} /> : <span className="map-breadcrumb">{breadCrumb}</span>
					</div>
				</div>

                <div className="map-view">
                    <GoogleMapReact
                        center={center}
                        defaultZoom={mapZoom}
                        options={this.context.getOptions}
                        onGoogleApiLoaded={({map, maps}) => this.context.googleApiLoaded(map, maps)}
                        yesIWantToUseGoogleMapApiInternals
                        bootstrapURLKeys={{key: `AIzaSyCRBpL_vGgXtWHyZgsN_2BGYOfAR-VJQ24`}}
                    />
                </div>
            </Col>
            {isOpenStreetViewModal ?
            <StreetViewModal
                onCloseModal={this.context.onCloseModal}
                streetViewLabel={streetViewLabel}
                isLodingStreet={isLodingStreet}
                streetViewAvailable={streetViewAvailable}
            /> : ""}
                {
                    isNodeviceLocationFound ?
                        <ModalWindow
                            show={this.context.state.isNodeviceLocationFound}
                            onHide={this.context.modalClose}
                            size={"md"}
                            dialogClassName={""}
                            title={ <FormattedMessage id={`nav.main.locNotFound`} /> }
                            modeldata={ {
                                content:<div class="d-flex align-items-center justify-content-center noDeviceDiv"><FormattedMessage id={`nav.main.noDevice`} /></div> }}
                            footer={[]}
                        /> : null
                }

        </React.Fragment>
        );
    }
}

export default MapContainer;