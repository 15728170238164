import React, { Component, Fragment } from 'react';
import ModalWindow from '../../../../hoc/modelWindow';
import { EventPrecursorContext } from '../provider/eventPrecursorProvider';
import { FormattedMessage } from 'react-intl';
import { Row, Form, FormGroup, Col, FormControl } from 'react-bootstrap';
import DateRangePicker from "react-bootstrap-daterangepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import SingleSelect from '../../../../hoc/singleSelect';
import { eventPrecursorData } from '../../../../utility/eventPrecursorUtility';
import { addEventPrecursor } from '../services/eventPrecursorService';
import AlertMessage from '../../../../hoc/alert';
import TextArea from '../../../../hoc/form/textArea';
import { numberOnlyRegex } from '../../../../filters/commonFunction'
import Loader from '../../../../hoc/loader';

class AddEventPrecursorModal extends Component {
    static contextType = EventPrecursorContext;
    state = {
        modalTitle: <span>Field Inspection Details : <span className='font-weight-normal small'>{`${this.context.state.epTableSelectedRow.region} - ${this.context.state.epTableSelectedRow.substation} - ${this.context.state.epTableSelectedRow.feeder} - ${this.context.state.epTableSelectedRow.feedersite}`}</span></span>,
        addEventPrecursorFields: {
            "date_investigated": "",
            "issues_identified": "",
            "asset_location": "",
            "status": "",
            "phase":this.context.state.epTableSelectedRow.phase
        },
        issues_identified_options: [
            { "label": "--select--", "value": "" },
            { "label": "Equipment Failure", "value": "Equipment Failure" },
            { "label": "Vegetation", "value": "Vegetation" },
            { "label": "Both", "value": "Both" },
            { "label": "Unknown", "value": "Unknown" },
            { "label": "Other", "value": "Other" }
        ],
        asset_location_options: [
            { "label": "--select--", "value": "" },
            { "label": "Overhead Primary", "value": "Overhead Primary" },
            { "label": "Underground Primary", "value": "Underground Primary" },
            { "label": "Secondary/Service", "value": "Secondary/Service" },
        ],
        loader: false
    }
    componentDidMount() {
        document.getElementsByClassName('modal')[0].setAttribute('tabindex', '')
    }

    onBtnClickHandler = (value) => {
        value === 'save' ? this.saveEventPrecursor() : null;
    }
    onInputChangeHandler = (pVal, pKey) => {
        let lAddEventPrecursorFields = { ...this.state.addEventPrecursorFields };
        if (pKey === 'asset_location') lAddEventPrecursorFields.asset_type = '';
        this.setState({
            addEventPrecursorFields: {
                ...lAddEventPrecursorFields,
                [pKey]: pVal
            }
        });
    }
    validateFields = () => {
        let Obj = {
            valid: true,
            message: {
                status: false,
                msg: "",
                type: ""
            }
        }
        if (['asset_location','asset_type','issues_identified','status','date_investigated'].filter(e=>this.state.addEventPrecursorFields[e] === "").length > 0) {
            Obj = {
                valid: false,
                message: {
                    status: true,
                    msg: <FormattedMessage id="gm.form.message.mandatory" />,
                    type: "fail"
                }
            }
        } else if (this.state.addEventPrecursorFields.customers_impacted) {
            let valid = numberOnlyRegex.test(this.state.addEventPrecursorFields.customers_impacted)
            Obj = {
                valid: valid,
                message: {
                    status: !valid,
                    msg: !valid ? <FormattedMessage id="events.eventPrecursor.column.customers_impacted.integervalidationmsg" /> : "",
                    type: !valid ? "fail" : ""
                }
            }
        }
        return Obj;
    }
    saveEventPrecursor = () => {
        let lValidObj = this.validateFields();
        if (lValidObj.valid) {
            this.setState({loader:true})
            addEventPrecursor({
                'apiType': "data",
                'hierid':this.context.state.epTableSelectedRow.hierid,
                'feederid':this.context.state.epTableSelectedRow.feederid,
                'siteid':this.context.state.epTableSelectedRow.siteid,
                // 'REGION': this.context.state.epTableSelectedRow.region,
                // 'SUBSTATION': this.context.state.epTableSelectedRow.substation,
                // 'FEEDER': this.context.state.epTableSelectedRow.feeder,
                // 'FEEDERSITE': this.context.state.epTableSelectedRow.feedersite,
            }, { ...this.state.addEventPrecursorFields, "eventId": this.context.state.epTableSelectedRow.eventId,"date_investigated":moment(this.state.addEventPrecursorFields.date_investigated).format("YYYY-MM-DD") })
                .then(data => {
                    if (data && data.error) {
                        this.context.setMessage(true, "fail", data.error)
                    } else {
                        this.context.onUpdateActionValue(false, "");
                        this.context.setMessage(true, "success", <FormattedMessage id="events.eventPrecursor.column.addInspectionDetails.success" />)
                    }
                    this.setState({loader:false})
                }).catch(err => {
                    console.log(err)
                });
        } else {
            this.context.setMessage(lValidObj.message.status, lValidObj.message.type, lValidObj.message.msg)
        }
    }

    render() {
        let modalBody =
            <div>
                {this.state.loader ? <Loader elementClass="tree-ajax-loader center-element" /> : null}
                <Form>
                    <Row className='d-flex justify-content-center mb-3'>{this.context.state.message.type === "fail" ? <AlertMessage type="danger" timer={10000} message={this.context.state.message.msg} setVisible={() => this.context.onCloseAlertMessage()} /> : null}</Row>
                    <FormGroup key="date" className="clearfix">
                        <Col sm={4} xs={4} className="float-left mb-1 m-0 p-0">
                            <FormattedMessage id={`events.eventPrecursor.column.date_investigated`} />*
                        </Col>
                        <Col sm={8} xs={8} className="float-left mb-1 d-flex align-items-center">
                            <FormControl type="text" label="Text" className="" onChange={(e) => this.onInputChangeHandler(e, "date_investigated")}
                                value={this.state.addEventPrecursorFields.date_investigated ? this.state.addEventPrecursorFields.date_investigated : ""} disabled />
                            <DateRangePicker
                                initialSettings={{
                                    singleDatePicker: true,
                                    showDropdowns: true,
                                    //startDate: moment(dateConvertion(formData[item.value], 'endDate', this.context.state.timezone)),
                                    minDate: `${new Date(new Date().setMonth(new Date().getMonth() - 13)).getMonth() + 1}/${new Date(new Date().setMonth(new Date().getMonth() - 6)).getDate()}/${new Date(new Date().setMonth(new Date().getMonth() - 6)).getFullYear()}`,
                                    maxYear: parseInt(moment().add(20, "year").format('YYYY'), 10),
                                    //maxDate: moment(dateConvertion(formData.endTimestamp, 'endDate', this.context.state.timezone)),
                                    maxDate: moment(new Date()),
                                    startDate: moment(new Date()),
                                    showISOWeekNumbers: true,
                                    opens: 'left',
                                    autoApply: true,
                                }}
                                onCallback={(e, picker) => this.onInputChangeHandler(moment(new Date(e)).format('MM/DD/YYYY'), "date_investigated")}
                            >
                                <span className="date-icon icon-width float-right"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                            </DateRangePicker>
                        </Col>
                    </FormGroup>
                    <FormGroup key="issue-identified" className="clearfix single-select-dropdown">
                        <Col sm={4} xs={4} className="float-left mb-1 m-0 p-0">
                            <FormattedMessage id={`events.eventPrecursor.column.issueIdentified`} />*
                        </Col>
                        <Col sm={8} xs={8} className="float-left mb-1 ">
                            <SingleSelect disabled={false} data={this.state.issues_identified_options ? this.state.issues_identified_options.sort((a,b)=>  a.label.localeCompare(b.label)) : []} setType={(evt) => this.onInputChangeHandler(evt, "issues_identified")} value={this.state.addEventPrecursorFields.issues_identified ? this.state.addEventPrecursorFields.issues_identified : "--select--"} icon='ok' />
                            <span className="float-right caret-down"><FontAwesomeIcon icon={faCaretDown} /></span>
                        </Col>
                    </FormGroup>
                    <FormGroup key="asset-location" className="clearfix single-select-dropdown">
                        <Col sm={4} xs={4} className="float-left mb-1 m-0 p-0">
                            <FormattedMessage id={`events.eventPrecursor.column.asset_location`} />*
                        </Col>
                        <Col sm={8} xs={8} className="float-left mb-1 ">
                            <SingleSelect disabled={false} data={this.state.asset_location_options ? this.state.asset_location_options.sort((a,b)=>  a.label.localeCompare(b.label)) : []} setType={(evt) => this.onInputChangeHandler(evt, "asset_location")} value={this.state.addEventPrecursorFields.asset_location ? this.state.addEventPrecursorFields.asset_location : "--select--"} icon='ok' />
                            <span className="float-right caret-down"><FontAwesomeIcon icon={faCaretDown} /></span>
                        </Col>
                    </FormGroup>
                    {
                        this.state.addEventPrecursorFields.asset_location ? eventPrecursorData.addEventPrecursorFields.map(field => {
                            let label, template = null;
                            label = <FormattedMessage id={`events.eventPrecursor.column.${field.key}`} />;
                            template = (field.type === 'input') ?
                                <FormControl type="text" label="Text" className="inpFlSz" onChange={(e) => this.onInputChangeHandler(e.currentTarget.value, field.key)} value={this.state.addEventPrecursorFields[field.key] ? this.state.addEventPrecursorFields[field.key] : ""} maxLength={field.maxLength ? field.maxLength : null} />
                                : (field.type === 'select') ?
                                    <Fragment>
                                        <SingleSelect disabled={field.isDisabled} data={field.key !== "asset_type" ? field.options.length > 0 ? field.options.sort((a,b)=>  a.label.localeCompare(b.label)) : [{ label: "--select--", value: "--select--" }] : field.options[this.state.addEventPrecursorFields.asset_location].length > 0 ? field.options[this.state.addEventPrecursorFields.asset_location].sort((a,b)=>  a.label.localeCompare(b.label)) : [{ label: "--select--", value: "--select--" }]} setType={(e) => this.onInputChangeHandler(e, field.key)} value={this.state.addEventPrecursorFields[field.key] ? this.state.addEventPrecursorFields[field.key] : "--select--"} icon='ok' />
                                        <span className="float-right caret-down"><FontAwesomeIcon icon={faCaretDown} /></span>
                                    </Fragment>
                                    : (field.type === 'textarea') ? <TextArea
                                        type="textarea"
                                        rows={5}
                                        value={this.state.addEventPrecursorFields[field.key] || ""}
                                        field={field.value}
                                        handleOnchange={(e) => { this.onInputChangeHandler(e, field.key) }}
                                    /> : null
                            let data = label && template ?
                                <FormGroup key={field.key} className="clearfix single-select-dropdown">
                                    <Col sm={4} xs={4} className="float-left mb-2 m-0 p-0">
                                        {label}{field.isMandatory ? "*" : ""}
                                    </Col>
                                    <Col sm={8} xs={8} className="float-left mb-2">
                                        {template}
                                    </Col>
                                </FormGroup> : ""
                            return (data)
                        }) : null
                    }
                </Form>
            </div>
        return (
            <Row>
                <ModalWindow
                    show={this.context.state.showModal}
                    size="lg"
                    dialogClassName={"userModal modal-dialog-centered addEventPrecrsorModal"}
                    title={this.state.modalTitle}
                    onBtnClickHandler={this.onBtnClickHandler}
                    onHide={() => this.context.onCloseModal()}
                    modeldata={{
                        content: modalBody
                    }}
                    footer={
                        [
                            { className: "modalSaveBtn", name: <FormattedMessage id='dm.column.save' />, value: "save", disabled: this.state.loader }
                        ]
                    }
                />
            </Row>
        )
    }
}
export default AddEventPrecursorModal;